export default function timeAgo(time, language) {
  switch (typeof time) {
    case 'number':
      break;
    case 'string':
      time = +new Date(time);
      break;
    case 'object':
      if (time.constructor === Date) {
        time = time.getTime();
      }
      break;
    default:
      time = +new Date();
  }
  const time_formats = [
    [60, language?.second, 1], // 60
    [120, language?.minutes_ago, '1 minute from now'], // 60*2
    [3600, language?.minutes, 60], // 60*60, 60
    [7200, language?.hour_ago, '1 hour from now'], // 60*60*2
    [86400, language?.hour, 3600], // 60*60*24, 60*60
    [172800, language?.yesterday, 'Tomorrow'], // 60*60*24*2
    [604800, language?.days, 86400], // 60*60*24*7, 60*60*24
    [1209600, language?.last_week, 'Next week'], // 60*60*24*7*4*2
    [2419200, language?.weeks, 604800], // 60*60*24*7*4, 60*60*24*7
    [4838400, language?.last_month, 'Next month'], // 60*60*24*7*4*2
    [29030400, language?.months, 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
    [58060800, language?.years, 'Next year'], // 60*60*24*7*4*12*2
    [2903040000, language?.years, 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
    [5806080000, 'Last century', 'Next century'], // 60*60*24*7*4*12*100*2
    [58060800000, 'centuries', 2903040000], // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
  ];
  let seconds = (+new Date() - time) / 1000,
    token = language?.ago,
    list_choice = 1;

  if (seconds === 0) {
    return 'Just now';
  }
  if (seconds < 0) {
    seconds = Math.abs(seconds);
    token = 'from now';
    list_choice = 2;
  }
  let i = 0,
    format;
  while ((format = time_formats[i++])) {
    if (seconds < format[0]) {
      if (typeof format[2] === 'string') {
        return format[list_choice];
      } else {
        return Math.floor(seconds / format[2]) + ' ' + format[1] + ' ' + token;
      }
    }
  }
  return time;
}
