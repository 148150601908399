/**
 * The function `updateDispatchApi` sends a PUT request to a specified URI with authentication and
 * language headers, handling errors and returning the response data.
 * @param {getRequestQueryParameter} props - The `props` parameter in the `updateDispatchApi` function
 * is an object with two properties:
 * @returns The `updateDispatchApi` function is returning a Promise that resolves to a
 * `UnifiedResponse` object. The `UnifiedResponse` object contains two properties:
 * 1. `data`: This property contains the data returned from the API call, which is obtained by calling
 * `response.json()` on the API response.
 * 2. `response`: This property contains the full API response object.
 */
import getConfigByKeys from '../../config/config';
import { configKeys } from '../../config/types';
import handleHttpError from '../api/httpErrorMapping';
import HttpStatusCode from '../../apis/types/httpStatusCodesEnums.type';
import UnifiedResponse from '../../apis/types/unifiedResponse.type';

import { DEFAULT_LANGUAGE_ID } from '../../constant/language';

import {
  getAuthTokenFromStorage,
  getUserLanguageFromStorage,
  getUserProfileFromStorage,
} from '../../modal/auth/auth.model';

const { API_URL } = getConfigByKeys([configKeys.API_URL]);

interface getRequestQueryParameter {
  payload: any;
  uri: string;
}

export default async function PutRequest(
  props: getRequestQueryParameter,
): Promise<UnifiedResponse> {
  const { payload, uri } = props;
  const Auth = getAuthTokenFromStorage();
  let userLanguage = getUserLanguageFromStorage()?.langId || null;
  const userProfile = getUserProfileFromStorage() || null;

  if (!userLanguage) {
    userLanguage = DEFAULT_LANGUAGE_ID;
  }

  const response = await fetch(`${API_URL}${uri}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: Auth ? 'Bearer ' + Auth : null,
      language: userLanguage.toString(),
      appVersion: userProfile?.appVersion ? userProfile?.appVersion : null,
      'fmt-installation-id': userProfile?.fmtInstallationId
        ? userProfile?.fmtInstallationId
        : null,
      'fmt-device-id': userProfile?.uniqueDeviceId
        ? userProfile?.uniqueDeviceId
        : null,
    },
    body: JSON.stringify(payload),
  });

  if (response.status !== HttpStatusCode.Ok) {
    throw handleHttpError(response.status);
  }

  const data = await response.json();
  return { data, response };
}
