import React from 'react';
import { Box, Button } from '@mui/joy';
import { ArrowForwardRounded, TimelineOutlined } from '@mui/icons-material';
import { freeSMSAnimations } from '../../../constant/smsFeatureUrl';
import Typography from '../../../ComponentV3/Typography';
import RiveComponent from '../../../utils/riveComponent';
import { SMSBalanceContainerProps } from '../../../hooks/SMSLandingScreen/types';
import { getLanguageData } from '../../../service/languageService';

export default function SMSBalanceContainer({
  currentMonth,
  noOfDays,
  noOfSMSSent,
  totalSMSCredit,
  onClickViewSMSHistory,
  language,
}: SMSBalanceContainerProps) {
  const languageData = getLanguageData();
  // Get the active system language from the user's browser settings
  const activeSystemLang = Intl.DateTimeFormat().resolvedOptions().locale;
  // Determine the system language code and backend language code based on whether the language is English
  const systemLanguage = activeSystemLang?.includes('en')
    ? languageData && languageData[1].langCode
    : activeSystemLang?.includes('mr')
    ? languageData && languageData[2].langCode
    : languageData && languageData[0].langCode;

  const langId = JSON.parse(localStorage.getItem('userLanguage'));
  const selectedLanguage = langId?.langCode ? langId?.langCode : systemLanguage;

  const everyMonthSMSAnimationURL =
    selectedLanguage === (languageData && languageData[0].langCode)
      ? freeSMSAnimations.hindi
      : selectedLanguage === (languageData && languageData[2].langCode)
      ? freeSMSAnimations.marathi
      : freeSMSAnimations.english;

  console.log('everyMonthSMSAnimationURL', everyMonthSMSAnimationURL);
  const replacePlaceholders = ({ template, values }) => {
    return template
      .split(/(<[^>]+>)/)
      .map((part, index) =>
        part.startsWith('<') && part.endsWith('>') ? (
          <span key={index}>{values[part.replace(/<|>/g, '')]}</span>
        ) : (
          <span key={index}>{part}</span>
        ),
      );
  };

  const smsBalanceForCurrentMonthText =
    language?.smsBalanceForCurrentMonth &&
    replacePlaceholders({
      template: language?.smsBalanceForCurrentMonth,
      values: { currentMonth: currentMonth },
    });

  const smsSentInLastFewDaysText =
    language?.smsSentInLastFewDays &&
    replacePlaceholders({
      template: language?.smsSentInLastFewDays,
      values: { noOfSMSSent: noOfSMSSent, noOfDays: noOfDays },
    });

  return (
    <Box
      marginTop={4}
      marginX={4}
      marginBottom={5}
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
    >
      <Box display="flex" justifyContent="center" width="100%" marginBottom={2}>
        <RiveComponent
          src={everyMonthSMSAnimationURL}
          autoplay={true}
          styles={{ height: '28px' }}
        />
      </Box>
      <Typography level="label-sm">{smsBalanceForCurrentMonthText}</Typography>
      <Typography paddingBottom={2} level="h2" fontFamily={'sans-serif'}>
        {totalSMSCredit}
      </Typography>
      <Button
        size={'sm'}
        variant="soft"
        color="neutral"
        style={{ borderRadius: 8 }}
        onClick={onClickViewSMSHistory}
        id="onClickViewSMSHistorySMSLandingTestId"
        endDecorator={<ArrowForwardRounded style={{ color: '#32383E' }} />}
      >
        {language?.viewSMSHistory}
      </Button>
      {noOfSMSSent > 0 ? (
        <Box
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          marginTop={4}
          data-testid="smsSentDetails"
        >
          <TimelineOutlined
            style={{ color: '#1487FD', height: 14, width: 14 }}
            data-testid="timelineIcon"
          />
          <Typography
            level={'label-md'}
            style={{ color: '#1487FD' }}
            data-testid="smsSentText"
          >
            {smsSentInLastFewDaysText}
          </Typography>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
}
