import { useEffect, useState } from 'react';
import { GetOtp } from './types';
import GetOtpApi from '../../modal/Traders/models/getOtp';
import encryptAsAES from '../../utils/encryption/aes';
import CreateTextForEncryption from '../../utils/mobileEncryption';
import ValidateOtp from '../../modal/Traders/models/validateOtp';
import UpdatePersonaApi from '../../modal/Traders/models/updatePersona';
import {
  setAuthTokenToStorage,
  setProfileToStorage,
} from '../../modal/auth/models/auth.model';
import GetMerchantProfile from '../../modal/Traders/models/GetMerchantProfile';
import getLang from '../../languages/tradeList.js';
import { trackEvent, addUniqueId } from '../../utils/moengage';
import Events from '../../constant/MoengageEvents';
import CTUserTypeEnums from '../../constant/CTUserTypeEnums';
import UpdateUserLocation from '../../modal/Traders/models/UpdateUserLocation';
import { userType } from '../../constant/enums/userType';
import { useDispatch } from 'react-redux';
import { setUserPersona } from '../../redux/slices/personaSlice';
import { languageUrls } from '../../constant/languageUrls/index';
import useAppLanguage from '../../utils/applanguage';

export default function UseGetOtp(): GetOtp {
  const { language } = useAppLanguage(languageUrls?.tradeList, getLang);
  const [otpModalVisible, setOtpModalVisible] = useState<boolean>(false);
  const [mobileNumberModalVisible, setMobileNumberModalVisible] =
    useState<boolean>(false);
  const [personaModalVisible, setPersonaModalVisible] =
    useState<boolean>(false);
  const [userMobileNumber, setUserMobileNumber] = useState<string>('');
  const [userOtp, setUserOtp] = useState<string>('');
  const [userSelectedPersona, setUserSelectedPersona] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [isLocationModal, setLocationModal] = useState<boolean>(false);
  const langId = JSON.parse(localStorage.getItem('userLanguage'));
  const currentLanguageId = langId?.langCode || 'HI';
  const [askForPermission, setAskFormPermission] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [userBlocked, setUserBlocked] = useState<boolean>(false);
  const [disableResend, setDisableResend] = useState<boolean>(true);
  const dispatch = useDispatch();

  const getMobileNumber = (number: string) => {
    setUserBlocked(false);
    setUserMobileNumber(number);
    if (error) {
      setError(false);
    }
  };

  function onClickLoginProceed() {
    if (userMobileNumber?.length < 10) {
      return setError(true);
    }
    trackEvent(Events.SGN_MOBILE_SUBMITTED, {
      mobile_number: userMobileNumber,
    });

    setUserOtp('');
    getOtp();
  }

  function onClickValidateOtpProceed() {
    if (userOtp?.length < 4) {
      return setError(true);
    }
    validateOtp();
  }

  const getUserOtpFromInput = (newOtp: string) => {
    setUserOtp(newOtp);
    if (error) {
      return setError(false);
    }
  };

  async function getOtp() {
    setLoading(true);
    const encryptedText = await encryptAsAES(
      CreateTextForEncryption(userMobileNumber),
    );
    try {
      await GetOtpApi({
        mobile_number: encryptedText,
        country_code: '91',
        message_id: '',
      });
      setError(false);
      trackEvent(Events.SGN_OTP_SUBMITTED, { api_faluire: true });
      setMobileNumberModalVisible(false);
      setOtpModalVisible(true);
    } catch (error) {
      if (error?.code === 404) {
        setUserBlocked(true);
      }
      trackEvent(Events.SGN_OTP_SUBMITTED, { api_faluire: false });
      setLoading(false);
      return setError(true);
    } finally {
      setLoading(false);
    }
  }

  function locationAccessCheck() {
    navigator.permissions.query({ name: 'geolocation' }).then((result) => {
      if (result.state === 'prompt') {
        setLocationModal(true);
      } else {
        setAskFormPermission(true);
      }
      // Don't do anything if the permission was denied.
    });
  }

  function updateUserinfo(merchantProfile) {
    setProfileToStorage(merchantProfile);
  }

  async function validateOtp() {
    setLoading(true);
    try {
      const otpValidateresponse = await ValidateOtp({
        mobile_number: userMobileNumber,
        otp: userOtp,
        role_id: 6,
      });
      if (typeof otpValidateresponse.token !== 'string') {
        return setError(true);
      }

      setAuthTokenToStorage(otpValidateresponse.token);
      setOtpModalVisible(false);
      setError(false);
      addUniqueId(userMobileNumber);
      localStorage.setItem('loged', 'true');
      const merchantProfile = await GetMerchantProfile();
      if (!merchantProfile?.merchant_occupation_id) {
        trackEvent(Events.SGN_OTP_SUBMITTED, { is_fresh_user: true });
        onClickProceedPersona();
      } else {
        dispatch(
          setUserPersona(merchantProfile.merchant_occupation_id.toString()),
        );
        updateUserinfo(merchantProfile);
        locationAccessCheck();
      }
      const stateId = localStorage.getItem('stateId');
      const districtId = localStorage.getItem('districtId');

      if (stateId && districtId) {
        await UpdateUserLocation({
          feature_id: 13, //mandatory
          state_id: JSON.parse(stateId), //mandatory ,
          district_id: JSON.parse(districtId),
        });
      }
    } catch (error) {
      setLoading(false);
      return setError(true);
    } finally {
      setLoading(false);
    }
  }

  function changeMobileNumber() {
    setUserMobileNumber('');
    trackEvent(Events.SGN_CHANGE_MOBILE, {});

    setOtpModalVisible(false);
    setError(false);
    setMobileNumberModalVisible(true);
  }

  function onClickPersonaCard(perosnaId: string) {
    return () => {
      setUserSelectedPersona(perosnaId);
    };
  }

  async function onClickProceedPersona() {
    setLoading(true);
    try {
      await UpdatePersonaApi({
        occupation_id: userType['trader'],
      });
      trackEvent(Events.SGN_PROFILE_SUBMITTED, {
        user_type: CTUserTypeEnums.get(userSelectedPersona),
      });

      setPersonaModalVisible(false);
      locationAccessCheck();
    } catch (error) {
      console.log(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }

  const resendOtp = () => {
    trackEvent(Events.SGN_RESEND_OTP, {});

    getOtp();
  };

  useEffect(
    function () {
      if (mobileNumberModalVisible === true) {
        sessionStorage.clear();
      }
    },
    [mobileNumberModalVisible],
  );

  return {
    otpModalVisible,
    onClickLoginProceed,
    mobileNumberModalVisible,
    getMobileNumber,
    getUserOtpFromInput,
    userOtp,
    onClickValidateOtpProceed,
    changeMobileNumber,
    personaModalVisible,
    onClickPersonaCard,
    userSelectedPersona,
    onClickProceedPersona,
    resendOtp,
    setMobileNumberModalVisible,
    error,
    loading,
    userMobileNumber,
    language,
    isLocationModal,
    setLocationModal,
    userBlocked,
    currentLanguageId,
    disableResend,
    setDisableResend,
    askForPermission,
  };
}
