import React from 'react';
import Styles from './style.module.css';
import Header from './Header/Header';
import LoginInput from './LoginInput/Login';
import Actionbutton from './BottomContainer/Actionbutton';
import LanguageSelection from './LanguageSelection/LanguageSelection';
import { MobileInputProps } from './../../hooks/Login/types';
import { Box } from '@mui/joy';

export default function Login(props: MobileInputProps) {
  const {
    error,
    getMobileNumber,
    userBlocked,
    userMobileNumber,
    onClickLoginProceed,
    loading,
    handleLanguageSelect,
    onLanguageModalToggle,
    handleLanguageChange,
    selectedLanguage,
    isLanguageModalOpen,
    isLanguageSelectedInProgress,
    onClickPrivacyPolicy,
    language,
    onContactUs,
    loginScreenAssets,
    languageData,
  } = props;
  return (
    <Box className={Styles.container} bgcolor={'background.surface'}>
      <Header
        onLanguageModalToggle={onLanguageModalToggle}
        language={language}
        farmartLogo={loginScreenAssets.farmartLogo}
        languageSelectionIcon={loginScreenAssets.languageSelection}
      />
      <LoginInput
        error={error}
        getMobileNumber={getMobileNumber}
        userBlocked={userBlocked}
        userMobileNumber={userMobileNumber}
        language={language}
      />
      <Actionbutton
        onClickLoginProceed={onClickLoginProceed}
        loading={loading}
        userMobileNumber={userMobileNumber}
        onClickPrivacyPolicy={onClickPrivacyPolicy}
        language={language}
        onContactUs={onContactUs}
        whatsAppIconUrl={loginScreenAssets.whatsappIcon}
      />
      <LanguageSelection
        handleLanguageChange={handleLanguageChange}
        selectedLanguage={selectedLanguage}
        isLanguageModalOpen={isLanguageModalOpen}
        isLanguageSelectedInProgress={isLanguageSelectedInProgress}
        handleLanguageSelect={handleLanguageSelect}
        onLanguageModalToggle={onLanguageModalToggle}
        language={language}
        activeRadioButton={loginScreenAssets.activeRadioButton}
        inactiveRadioButton={loginScreenAssets.inactiveRadioButton}
        languageData={languageData}
      />
    </Box>
  );
}
