import { languageUrls as devLangUrls } from './dev-languageUrls.js';
import { languageUrls as stageLangUrls } from './stage-languageUrls.js';
import { languageUrls as prodLangUrls } from './prod-languageUrls.js';

export const languageUrls = getEnvLangFiles();

function getEnvLangFiles() {
  switch (import.meta.env.VITE_ENV) {
    case 'development':
      return devLangUrls;
    case 'staging':
      return stageLangUrls;
    case 'production':
      return prodLangUrls;
    default:
      return devLangUrls;
  }
}
