import { defaultLanguageCode } from './appLanguages';

const quizImagesData = new Map([
  [
    'HI',
    'https://d2n0idf0n5xz1f.cloudfront.net/metadata/quiz_image/quiz_hi_v2.png',
  ],
  [
    'EN',
    'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/11/18/559a0cd3-335f-4302-b6aa-924dd2d61637_Quiz%20english.png',
  ],
  [
    'MR',
    'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/11/18/1d433b4a-1795-4f8a-8700-67de5f9cedc6_Quiz%20Marathi.png',
  ],
]);

export function getQuizImage(langCode) {
  if (!langCode) {
    langCode = defaultLanguageCode;
  }

  const quizImage = quizImagesData.get(langCode);

  return quizImage || quizImagesData.get(defaultLanguageCode);
}
