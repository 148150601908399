import React, { useEffect, useState } from 'react';
import Styles from './style.module.css';
import timeAgo from '../../../utils/timeAgo/date/time_ago.js';
import { getLanguageService } from '../../../utils/getLanguageService';
import { languageUrls } from '../../../constant/languageUrls/index';

import { TradePriceBreakupData } from '../../../modal/Traders/types';
interface TradeDetailProps {
  PriceBreakup: TradePriceBreakupData;
  language?: { [key: string]: any };
  currentLanguageId?: string;
}
const ItemDetails = (props: TradeDetailProps) => {
  const { PriceBreakup, language, currentLanguageId } = props;
  const disabled = PriceBreakup?.is_expire === 1 ? true : false;
  const [timeAgoLang, setTimeAgoLang] = useState<{
    [key: string]: string;
  } | null>(null);

  useEffect(() => {
    getLanguageJson();
  }, []);

  async function getLanguageJson() {
    try {
      const url = languageUrls?.timeAgo;
      const result = await getLanguageService(url);
      setTimeAgoLang(result);
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  return (
    <div className={Styles.wrapper}>
      <div className={Styles.cropDetails}>
        <div className={Styles.imageWrapper}>
          <img
            src={PriceBreakup?.crop_variety_image}
            alt="Crop"
            className={Styles.cropImage}
            loading="eager"
          />
        </div>
        <div className={Styles.divider} />
        <div className={Styles.infoWrapper}>
          <div className={Styles.grossPriceText}>
            {PriceBreakup?.gross_price} {PriceBreakup?.gross_price_unit}
          </div>
          <div className={Styles.cropText}>
            {PriceBreakup?.crop_name}
            <div className={Styles.cropDetailsDividerDot}>●</div>
            {PriceBreakup?.variety_name}
          </div>
          <div className={Styles.lastUpdatedContainer}>
            <div>
              {language?.lastUpdate}{' '}
              {timeAgoLang && timeAgo(PriceBreakup?.updated_at, timeAgoLang)}
            </div>
            {disabled ? (
              <div className={Styles.expiredLabelWrapper}>
                {language?.priceExpired}
              </div>
            ) : PriceBreakup.is_sauda_booked ? (
              <div className={Styles.saudaRequestedLabel}>
                {language?.saudaRequested}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemDetails;
