import React from 'react';
import PinCodeView from '../../Pages/PinCode/PinCodeView';
import PinCodeHook from '../../hooks/PinCode/PinCodeHook';

export default function RakeList() {
  const {
    onSearchPinCode,
    pinCodeSearchValue,
    loading,
    totalPinCodeCounts,
    pinCodeList,
    onClickLocation,
    onPressBack,
    language,
    pincodeAssets,
    onContactUs,
  } = PinCodeHook();
  return (
    <PinCodeView
      onSearchPinCode={onSearchPinCode}
      pinCodeSearchValue={pinCodeSearchValue}
      loading={loading}
      totalPinCodeCounts={totalPinCodeCounts}
      pinCodeList={pinCodeList}
      onClickLocation={onClickLocation}
      onPressBack={onPressBack}
      language={language}
      pincodeAssets={pincodeAssets}
      onContactUs={onContactUs}
    />
  );
}
