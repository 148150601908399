import React from 'react';
import Style from './style.module.css';

import ButtonV2 from '../../../ComponentV2/ButtonV2';
import Modal from '../../../ComponentV2/Modal';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';

const InfoIcon = () => <InfoRoundedIcon fontSize="small" />;

const PaymentTermsModal = ({
  showPaymentTermsModal,
  onClosePaymentTermsModal,
  closePaymentTermsModal,
  paymentTermsType,
  priceBreakUp,
  handleBrokeragePopup,
  handleFinancingPopup,
  handleNetPopup,
  language,
  magicIcon,
}) => {
  const replaceTwo = ({ text, replaceText1, replaceText2 }) => {
    if (!text) {
      return;
    }
    const splitText = text.split(/{|}/);

    return splitText.map((item, index) => {
      if (item.startsWith('(') && item.endsWith(')')) {
        return (
          <span key={'buyerDetailPaymentTermModalText' + index}>
            {replaceText1}
          </span>
        );
      } else if (item.startsWith('*') && item.endsWith('*')) {
        return (
          <span key={'buyerDetailPaymentTermModalText' + index}>
            {replaceText2}
          </span>
        );
      }
      return <>{item}</>;
    });
  };

  const renderTextWithColors = ({ text, replaceText }) => {
    const splitText = text.split(/{|}/);
    return splitText.map((item, index) => {
      if (item.startsWith('(') && item.endsWith(')')) {
        return (
          <span key={'buyerDetailProceDetailColorText' + index}>
            {replaceText}
          </span>
        );
      }
      return <>{item}</>;
    });
  };

  const typePriceBreakup =
    paymentTermsType === 'grn'
      ? priceBreakUp.price_breakup_grn
      : priceBreakUp.price_breakup_buyer;
  const modalContent = (
    <div className={Style.container}>
      <div className={Style.card}>
        <img
          src={magicIcon}
          height={20}
          width={20}
          alt="Magic Icon"
          style={{ minWidth: 20, maxWidth: 20 }}
        />
        <label className={Style.cardText}>
          {paymentTermsType === 'grn'
            ? replaceTwo({
                text: typePriceBreakup?.discounted_fee
                  ? language?.discountedGrnTermToolTip
                  : language?.grnTermToolTip,
                replaceText1: `${
                  typePriceBreakup?.brokerage_price === null
                    ? 0
                    : typePriceBreakup?.brokerage_price
                } ${
                  typePriceBreakup?.brokerage_price_unit === null
                    ? ''
                    : typePriceBreakup?.brokerage_price_unit
                }`,
                replaceText2: `${
                  typePriceBreakup?.discounted_fee ||
                  typePriceBreakup?.farmart_fee ||
                  0
                }%`,
              })
            : replaceTwo({
                text: language?.buyerTermToolTip,
                replaceText1: `${
                  priceBreakUp?.repayment_time?.value === null
                    ? 0
                    : priceBreakUp?.repayment_time?.value
                } ${
                  priceBreakUp?.repayment_time?.unit === null
                    ? ''
                    : priceBreakUp?.repayment_time?.unit
                }`,
                replaceText2: `${
                  typePriceBreakup?.brokerage_price === null
                    ? 0
                    : typePriceBreakup?.brokerage_price
                } ${
                  typePriceBreakup?.brokerage_price_unit === null
                    ? ''
                    : typePriceBreakup?.brokerage_price_unit
                }`,
              })}
        </label>
      </div>
      <div className={Style.paymentInfo}>
        <div className={Style.paymentDetail}>
          <label className={Style.title}>{language?.grossPrice}</label>
          <label className={Style.value}>{`${
            typePriceBreakup?.gross_price === null
              ? 0
              : typePriceBreakup?.gross_price
          } ${
            typePriceBreakup?.gross_price_unit === null
              ? ''
              : typePriceBreakup?.gross_price_unit
          }`}</label>
        </div>
        <div className={Style.paymentDetail}>
          <label className={Style.title}>{language?.cashDiscount}</label>
          <label className={Style.value}>{`${
            typePriceBreakup?.cash_discount === null
              ? 0
              : typePriceBreakup?.cash_discount
          } %`}</label>
        </div>
        <div className={Style.paymentDetail}>
          <label className={Style.title}>{language?.bagDeduction}</label>
          <label className={Style.value}>{`${
            typePriceBreakup?.bag_deduction === null
              ? 0
              : typePriceBreakup?.bag_deduction
          } ${
            typePriceBreakup?.bag_deduction_unit === null
              ? ''
              : typePriceBreakup?.bag_deduction_unit
          }`}</label>
        </div>
        <div className={Style.paymentDetail}>
          <label className={Style.title}>{language?.unloadingCharges}</label>
          <label className={Style.value}>{`${
            typePriceBreakup?.unloading_price === null
              ? 0
              : typePriceBreakup?.unloading_price
          } ${
            typePriceBreakup?.unloading_price_unit === null
              ? ''
              : typePriceBreakup?.unloading_price_unit
          }`}</label>
        </div>
        <div className={Style.border} />

        <div className={Style.paymentDetail}>
          <label className={Style.title}>{language?.buyerNetPrice}</label>
          <label className={Style.value}>{`${
            typePriceBreakup?.net_buyer_price === null
              ? 0
              : typePriceBreakup?.net_buyer_price
          } ${
            typePriceBreakup?.net_buyer_price_unit === null
              ? ''
              : typePriceBreakup?.net_buyer_price_unit
          }`}</label>
        </div>
        <div className={Style.border} />

        <div className={Style.paymentDetail}>
          <div className={Style.popup}>
            <label className={Style.title}>{language?.brokerage}</label>
            <div
              className={Style.info}
              onClick={(event: any) => {
                event.stopPropagation();
                handleBrokeragePopup('modal');
              }}
              data-testid="brokerageInfoIcon"
            >
              <InfoIcon />
            </div>
          </div>
          <label className={Style.value}>{`${
            typePriceBreakup?.brokerage_price === null
              ? 0
              : typePriceBreakup?.brokerage_price
          } ${
            typePriceBreakup?.brokerage_price_unit === null
              ? ''
              : typePriceBreakup?.brokerage_price_unit
          }`}</label>
        </div>
        <div className={Style.paymentDetail}>
          <div className={Style.popup}>
            <label className={Style.title}>{language?.financingCost}</label>
            <div
              className={Style.info}
              onClick={(event: any) => {
                event.stopPropagation();
                handleFinancingPopup('modal');
              }}
              data-testid="financeInfoIcon"
            >
              <InfoIcon />
            </div>
          </div>

          <label className={Style.value}>{`${
            typePriceBreakup?.farmart_fee === null
              ? 0
              : typePriceBreakup?.farmart_fee
          } ${
            typePriceBreakup?.farmart_fee_unit === null
              ? ''
              : typePriceBreakup?.farmart_fee_unit
          }`}</label>
        </div>

        {typePriceBreakup?.discounted_fee &&
        typePriceBreakup?.discounted_fee !== 0 &&
        typePriceBreakup?.discounted_fee !== null ? (
          <div className={Style.paymentDetail}>
            <label className={Style.discountTitle}>
              {renderTextWithColors({
                text: language?.discountFee,
                replaceText: typePriceBreakup?.discount_in_percent,
              })}
            </label>
            <div>
              <label className={Style.netDiscount}>
                {typePriceBreakup?.farmart_fee === null
                  ? 0
                  : typePriceBreakup?.farmart_fee}
              </label>
              <label className={Style.discountValue}>{`${
                typePriceBreakup?.discounted_fee === null
                  ? 0
                  : typePriceBreakup?.discounted_fee
              } ${
                typePriceBreakup?.discounted_fee_unit === null
                  ? ''
                  : typePriceBreakup?.discounted_fee_unit
              }`}</label>
            </div>
          </div>
        ) : null}

        <div className={Style.borderSolid} />
        <div className={Style.paymentDetail}>
          <div className={Style.popup}>
            <label className={Style.title}>{language?.netPrice}</label>
            <div
              className={Style.info}
              onClick={(event: any) => {
                event.stopPropagation();
                handleNetPopup('modal');
              }}
              data-testid="netPriceInfoIcon"
            >
              <InfoIcon />
            </div>
          </div>
          <label className={Style.netPrice}>{`${
            typePriceBreakup?.net_price === null
              ? 0
              : typePriceBreakup?.net_price
          } ${
            typePriceBreakup?.net_price_unit === null
              ? ''
              : typePriceBreakup?.net_price_unit
          }`}</label>
        </div>
      </div>
      <ButtonV2
        type={'secondary'}
        title={language?.popUpOkayButton}
        onClick={closePaymentTermsModal}
      />
    </div>
  );
  return (
    <Modal
      isOpen={showPaymentTermsModal}
      children={modalContent}
      title={
        paymentTermsType === 'grn'
          ? language?.atGRNTerms
          : language?.atBuyersTerms
      }
      onClose={onClosePaymentTermsModal}
      isCloseButtonOption={true}
    />
  );
};

export default PaymentTermsModal;
