import { DEFAULT_LANGUAGE } from "../constant/language";
// languageService.ts
let languageData: any = null;

export const setLanguageData = (data: any) => {
    languageData = data;
};

export const getLanguageData = () => languageData;


// Get the language code from system or localStorage 
export function getCurrentLanguage(getLang: any) {
    const languageData = getLanguageData();
    /**
    * Retrieve the language code from localStorage.
    */
    const langId = JSON.parse(localStorage.getItem("userLanguage"));// get the language code from localStorage
    try {
        const activeSystemLang = Intl.DateTimeFormat()?.resolvedOptions()?.locale;
        const systemLanguage = activeSystemLang?.includes("en")
            ? languageData.appLanguages[1].langCode
            : languageData.appLanguages[0].langCode;
        const language = getLang(langId?.langCode || systemLanguage || DEFAULT_LANGUAGE);
        return language;
    } catch (error) {
        console.error("Error getting language:", error);
        return getLang(DEFAULT_LANGUAGE); // fallback to default language
    }
}


// Get the language code from system or localStorage 
export async function getCurrentLanguageViaCdn(uri: string) {
    const languageData = getLanguageData();
    const langId = JSON.parse(localStorage.getItem("userLanguage"));// get the language code from localStorage
    try {
        const activeSystemLang = Intl.DateTimeFormat()?.resolvedOptions()?.locale;
        const systemLanguage = activeSystemLang?.includes("en")
            ? languageData.appLanguages[1].langCode
            : languageData.appLanguages[0].langCode;
        const response = await fetch(uri);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const result = await response.json();
        const getLang = (language) => result[language];
        const language = getLang(langId?.langCode || systemLanguage || DEFAULT_LANGUAGE);
        return language;
    } catch (error) {
        console.log(error);
    }
}
