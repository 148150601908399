/* This code snippet is a TypeScript React component called `SmsBalance`. Here's a breakdown of what it
does: */
import { Box } from '@mui/joy';
import React from 'react';
import MuiTypography from '../../../ComponentV3/Typography';

interface SmsBalanceProp {
  language: { [key: string]: string };
  smsBalance: number;
}

export default function SmsBalance(props: SmsBalanceProp) {
  const currentDate = new Date();

  // Convert the month index to the month name
  const currentMonthName = new Intl.DateTimeFormat('en-US', {
    month: 'long',
  }).format(currentDate);

  const replacePlaceholders = ({ template, values }) => {
    return template
      .split(/(<[^>]+>)/)
      .map((part, index) =>
        part.startsWith('<') && part.endsWith('>') ? (
          <span key={index}>{values[part.replace(/<|>/g, '')]}</span>
        ) : (
          <span key={index}>{part}</span>
        ),
      );
  };
  const { language, smsBalance } = props;

  const template = language?.smsHistoryTitle;
  const values = {
    month: currentMonthName,
  };
  return (
    <Box
      margin={'16px'}
      display={'flex'}
      alignItems={'center'}
      flexDirection={'column'}
    >
      <MuiTypography
        level="label-sm"
        textColor={'text.primary'}
        data-testid="smsBalanceMonthTestId"
      >
        {language?.smsHistoryTitle && replacePlaceholders({ template, values })}
      </MuiTypography>
      <MuiTypography
        level="h2"
        textColor={'text.primary'}
        fontFamily={'sans-serif'}
        data-testid="smsBalanceTestId"
      >
        {smsBalance}
      </MuiTypography>
    </Box>
  );
}
