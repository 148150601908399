// LocationContext.tsx
import React, { createContext, useContext, ReactNode } from 'react';
import Hook from './hook'; // Assuming your custom hook file is named Hook
import { ModalTypes } from './types';
import LocationModalView from './locationPage';

// Define the context type
type LocationContextType = ModalTypes;

// Create the context
const LocationContext = createContext<LocationContextType | undefined>(
  undefined,
);

// Create the provider component
export const LocationProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const LocationContextValue = Hook(); // Use your custom hook here

  return (
    <LocationContext.Provider value={LocationContextValue}>
      {children}
    </LocationContext.Provider>
  );
};

// Custom hook to consume the context
export const useLocation = (): LocationContextType => {
  const context = useContext(LocationContext);
  if (!context) {
    throw new Error('useLocation must be used within an LocationProvider');
  }
  return context;
};

export { LocationModalView };
