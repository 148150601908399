// Importing useState hook from React
import { useState, useEffect } from 'react';

// Importing necessary types
import { MobileInputProps, LanguageParams } from './types';

// Importing language related functions and utilities
import getLang from '../../languages/login.js';

// Importing encryption functions
import encryptAsAES from '../../utils/encryption/aes';

// Importing utility function for creating text for encryption
import CreateTextForEncryption from '../../utils/mobileEncryption';

// Importing necessary hooks from React Router DOM
import { useNavigate, useLocation } from 'react-router-dom';

// Importing constants related to Moengage events
import MoengageEvents from '../../constant/MoengageEvents';

// Importing utility function for tracking events
import { trackEvent } from '../../utils/moengage';

// Importing platform checker hook
import { usePlatfromData } from '../CrossPlatformActionHandler/platformChecker';

import { createBridge } from '../../rpcBridge';

import bridgeFunctions from '../../utils/bridge';

import CountryCode from '../../constant/CountryCodeEnums';
import PlatForm from '../../constant/platFormEnums';

import PostRequest from '../../utils/apiCaller/postRequest';
import { apiEndPoints } from '../../constant/apiEndPoints';
import { helpLineNumber } from '../../constant/HelpLineNumber';
import { getLoginScreenAssets } from '../../constant/imageUrls/index';
import { fetchLanguage } from './../../utils/languageApi/fetchLanguage';
import { languageUrls } from '../../constant/languageUrls/index';
import { DEFAULT_LANGUAGE } from '../../constant/language';
import { useLanguage } from '../../context/language';

// MobileSubmitHook component
export default function MobileSubmitHook(): MobileInputProps {
  const platformData = usePlatfromData();

  // Using navigation hook
  const navigate = useNavigate();

  // Getting start load time
  const startLoadTime = new Date().getTime();
  const { languageData } = useLanguage();

  const bridge = createBridge();
  useEffect(() => {
    (window as any).listenMessagesFromApp = function (request: any) {
      bridge.listenMessagesFromNative(request);
    };
  }, []);

  const [languageFile, setLanguageFile] = useState([]);

  useEffect(() => {
    getLanguageJson();
  }, []);

  async function getLanguageJson() {
    try {
      const url = languageUrls?.loginScreenLangageUrl;
      const result = await fetchLanguage(url);
      setLanguageFile(result);
    } catch (error) {
      console.log('object');
    } finally {
      console.log('object');
    }
  }

  const activeSystemLang = Intl.DateTimeFormat().resolvedOptions().locale;
  const systemLanguage = activeSystemLang?.includes('en')
    ? languageData && languageData[1].langCode
    : languageData && languageData[0].langCode;
  const systemLanguageId = activeSystemLang?.includes('en')
    ? languageData && languageData[1].backendLanguageCode
    : languageData && languageData[0].backendLanguageCode;

  // Retrieving language code from localStorage
  const langId = JSON.parse(localStorage.getItem('userLanguage'));

  // Getting location state
  const params = useLocation()?.state;

  // Getting language based on language code
  const language = languageFile
    ? languageFile[langId?.langCode ? langId?.langCode : systemLanguage]
    : getLang(DEFAULT_LANGUAGE);

  const loginScreenAssets = getLoginScreenAssets(langId?.langCode);

  // Initializing current language
  const currentLanguage = langId || {
    langCode: systemLanguage,
    langId: systemLanguageId,
  };

  // State variables
  const [isLanguageModalOpen, toggleLanguageModal] = useState<boolean>(false);
  const [isLanguageSelectedInProgress, setLanguageSelectedInProgeress] =
    useState<boolean>(false);
  const [selectedLanguage, setSelectedLanguage] = useState(currentLanguage);
  const [userBlocked, setUserBlocked] = useState<boolean>(false);
  const [userMobileNumber, setUserMobileNumber] = useState<string>(
    params?.userMobileNumber || '',
  );
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  // Function to handle language selection
  function handleLanguageSelect(languageItem: LanguageParams) {
    setSelectedLanguage(languageItem);
  }

  // Function to handle language change
  function handleLanguageChange() {
    // Handling language change
    if (localStorage.getItem('userLanguage') !== selectedLanguage) {
      setLanguageSelectedInProgeress(!isLanguageSelectedInProgress);
      localStorage.setItem('userLanguage', JSON.stringify(selectedLanguage));
      toggleLanguageModal(!isLanguageModalOpen);
      setLanguageSelectedInProgeress(false);
    } else {
      toggleLanguageModal(!isLanguageModalOpen);
    }
  }

  // Function to toggle language modal
  function onLanguageModalToggle() {
    setSelectedLanguage(
      langId || {
        langCode: systemLanguage,
        langId: systemLanguageId,
      },
    );
    toggleLanguageModal(!isLanguageModalOpen);
  }

  // Function to handle login proceed
  function onClickLoginProceed() {
    if (userMobileNumber?.length < 10) {
      return setError(true);
    }
    getOtp();
  }

  // Function to get OTP
  async function getOtp() {
    setLoading(true);
    const encryptedText = await encryptAsAES(
      CreateTextForEncryption(userMobileNumber),
    );
    try {
      // Making API call to get OTP

      const getOtpData = await PostRequest({
        payload: {
          mobile_number: encryptedText,
          country_code: CountryCode.get('india'),
          message_id: '',
        },
        uri: apiEndPoints.getOTP,
      });

      if (getOtpData.data.status) {
        // Tracking MoEngage events
        trackEvent(MoengageEvents?.onSGNMobileNumberSubmittedButtonClick, {
          mobile_number: userMobileNumber,
          'Time taken from clicking on submit button to enter the OTP':
            new Date().getTime() - startLoadTime,
          api_faluire: false,
        });
        setError(false);
        navigate('/otpSubmit', {
          state: {
            userMobileNumber: userMobileNumber,
          },
        });
      }
    } catch (error) {
      // Handling API error
      if (error?.code === 404) {
        setUserBlocked(true);
      }
      trackEvent(MoengageEvents?.onSGNMobileNumberSubmittedButtonClick, {
        mobile_number: userMobileNumber,
        'Time taken from clicking on submit button to enter the OTP':
          new Date().getTime() - startLoadTime,
        api_faluire: true,
      });
      setLoading(false);
      return setError(true);
    } finally {
      setLoading(false);
    }
  }

  // Function to get mobile number
  const getMobileNumber = (number: string) => {
    setUserBlocked(false);
    setUserMobileNumber(number);
    if (error) {
      setError(false);
    }
  };

  // Function to handle privacy policy and terms conditions click
  function onClickPrivacyPolicy(item: string) {
    return () => {
      if (item === language?.privacyPolicy) {
        trackEvent(MoengageEvents?.onPrivacyPolicyButtonClick, {});
      } else {
        trackEvent(MoengageEvents?.onTermsConditionsButtonClick, {});
      }
    };
  }

  // Function to handle help button
  function onContactUs() {
    const url = `https://wa.me/${helpLineNumber}?text=नमस्कार जी, मुझे आपसे कुछ सहायता चाहिये | मैने अभी FarMart एप डाउनलोड किया है ।`;
    if (platformData.platform === PlatForm.get('website')) {
      const win = window?.open(url, '_blank');
      win?.focus();
    } else {
      const text = `नमस्कार जी, मुझे आपसे कुछ सहायता चाहिये | मैने अभी FarMart एप डाउनलोड किया है । &phone=${helpLineNumber}`;
      const link = `whatsapp://send?text=${text}`;

      bridge.sendRequestToNative(
        'OpenUrl',
        {
          link: link,
        },
        (response) => {
          bridgeFunctions.PrintLog(response);
        },
      );
    }
  }

  /* This `useEffect` hook is setting up an event listener for the `popstate` event on the `window`
  object. When the user navigates back in the browser history, the `popstate` event is triggered. */
  useEffect(
    function () {
      function onClickBack() {
        navigate('/');
      }

      window.addEventListener('popstate', onClickBack);

      return () => {
        window.removeEventListener('popstate', onClickBack);
      };
    },
    [navigate],
  );

  useEffect(() => {
    setSelectedLanguage(
      langId || {
        langCode: systemLanguage,
        langId: systemLanguageId,
      },
    );
  }, [localStorage.getItem('userLanguage')]);

  // Returning props
  return {
    getMobileNumber,
    userBlocked,
    userMobileNumber,
    error,
    onClickLoginProceed,
    loading,
    handleLanguageSelect,
    onLanguageModalToggle,
    handleLanguageChange,
    selectedLanguage,
    isLanguageModalOpen,
    isLanguageSelectedInProgress,
    onClickPrivacyPolicy,
    language,
    onContactUs,
    loginScreenAssets,
    languageData,
  };
}
