/**
 * The `RakeDetailView` function in TypeScript React renders a component that displays rake details,
 * nearby rake information, and messages with lazy loading and suspense fallbacks.
 * @param {RakeDetailProps} props - The `props` object in the `RakeDetailView` component contains the
 * following properties:
 */
import React, { lazy, Suspense } from 'react';
import Styles from './style.module.css';
import { Box } from '@mui/joy';
import { RakeDetailProps } from '../../hooks/RakeDetail/types';
import MuiSkeleton from '../../MuiComponents/Skeleton';

/* The code snippet `const Header = lazy(() => import("./Header")); const RakeDetailList = lazy(() =>
import("./RakeDetailList")); const NearAreaRake = lazy(() => import("./NearAreaRake")); const
Message = lazy(() => import("./Message"));` is using the `lazy` function provided by React to
dynamically import components asynchronously. */
const Header = lazy(() => import('./Header'));
const RakeDetailList = lazy(() => import('./RakeDetailList'));
const NearAreaRake = lazy(() => import('./NearAreaRake'));
const Message = lazy(() => import('./Message'));

export default function RakeDetailView(props: RakeDetailProps) {
  const {
    /* These variables are destructured from the `props` object passed to the `RakeDetailView`
    component. Here is a brief explanation of each: */
    nearRakeLoading,
    nearRakeData,
    nearRakeCount,
    currentRakeData,
    currentRakeCount,
    currentLocation,
    onLoadMoreNearRake,
    currentRakeLoading,
    onClickRakeDetails,
    onPressBack,
    onClickHelp,
    language,
    uniqueKey,
    rakeDetailAssets,
  } = props;

  return (
    <Box className={Styles?.container} bgcolor={'#FFF'} key={uniqueKey}>
      <Suspense
        fallback={
          <MuiSkeleton sx={{ height: 60, width: '100%', marginBottom: 8 }} />
        }
      >
        <Header
          language={language}
          currentLocation={currentLocation}
          onClickHelp={onClickHelp}
          onPressBack={onPressBack}
        />
      </Suspense>
      <Suspense
        fallback={
          <MuiSkeleton
            sx={{
              height: 160,
              width: 'calc(100% - 32px)',
              marginBottom: 8,
              borderRadius: 8,
              boxSizing: 'border-box',
              alignSelf: 'center',
            }}
            itemNumber={4}
          />
        }
      >
        <RakeDetailList
          noRakeImage={rakeDetailAssets.noRake}
          currentRakeLoading={currentRakeLoading}
          currentRakeData={currentRakeData}
          language={language}
          currentRakeCount={currentRakeCount}
          currentLocation={currentLocation}
        />
        <NearAreaRake
          nearRakeData={nearRakeData}
          language={language}
          onLoadMoreNearRake={onLoadMoreNearRake}
          nearRakeCount={nearRakeCount}
          nearRakeLoading={nearRakeLoading}
          onClickRakeDetails={onClickRakeDetails}
          currentRakeCount={currentRakeCount}
        />
        <Message language={language} />
      </Suspense>
    </Box>
  );
}
