import getLang from '../../languages/mySaudaScreen.js';
import getLanguage from '../../utils/getLanguage/index';

const SaudaOpenCloseEnum = new Map();

const language = getLanguage(getLang);

SaudaOpenCloseEnum.set('openClose', [
  { label: language?.openSauda, id: 1, labelKey: 'openSauda' },
  { label: language?.closedSauda, id: 0, labelKey: 'closedSauda' },
]);

export default SaudaOpenCloseEnum;
