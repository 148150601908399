import React from 'react';
import Styles from './style.module.css';
import Modal from '../../../ComponentV2/Modal';
import { Crop, CropVariety } from '../../../modal/Traders/types';
import Loading from '../../../ComponentV2/Loading';
import RadioButtonCheckedRoundedIcon from '@mui/icons-material/RadioButtonCheckedRounded';
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';

interface PayloadType {
  isOpen: boolean;
  handleCropVarietyListModal: () => void;
  activeVariety: CropVariety;
  cropVarietyList: CropVariety[];
  handleVarietySelection: (cropVarietyId: CropVariety) => void;
  cropVarietyLoading: boolean;
  activeCrop: Crop;
  handleCropVarietySearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  cropVarietyListSearchParam: string;
  language: { [key: string]: any };
  searchIcon: string;
}

const SelectVarietyModal = ({
  isOpen,
  handleCropVarietyListModal,
  activeVariety,
  cropVarietyList,
  handleVarietySelection,
  cropVarietyLoading,
  activeCrop,
  handleCropVarietySearch,
  cropVarietyListSearchParam,
  language,
  searchIcon,
}: PayloadType) => {
  const UiModal = (
    <div className={Styles.wrapper}>
      <div className={Styles.searchWrapper}>
        <div className={Styles.searchField}>
          <div className={Styles.iconWrapper}>
            <img src={searchIcon} height={24} width={25} loading="eager" />
          </div>
          <input
            type="text"
            placeholder={language?.cropVarietyModalSearchPlaceholder}
            value={cropVarietyListSearchParam}
            onChange={handleCropVarietySearch}
            className={Styles.searchInput}
          />
        </div>
      </div>
      <div className={Styles.optionWrapper}>
        {cropVarietyLoading ? (
          <div className={Styles.loadingContainer}>
            <Loading size={20} color="grey" loaderWidth={3} />
          </div>
        ) : (
          cropVarietyList?.length > 0 &&
          cropVarietyList.map((item, index) => (
            <label key={index} className={Styles.listItem}>
              <div
                className={Styles.itemContainer}
                onClick={() => handleVarietySelection(item)}
              >
                <div
                  className={
                    activeVariety?.id === item?.id
                      ? Styles.activeVariety
                      : Styles.inactiveVariety
                  }
                >
                  {item.crop_variety_name}
                </div>
                <div>
                  {activeVariety?.id === item?.id ? (
                    <RadioButtonCheckedRoundedIcon
                      htmlColor="#008B74"
                      sx={{ height: 28, width: 28 }}
                    />
                  ) : (
                    <RadioButtonUncheckedRoundedIcon
                      htmlColor="#C2C7CF"
                      sx={{ height: 28, width: 28 }}
                    />
                  )}
                </div>
              </div>
            </label>
          ))
        )}
      </div>
    </div>
  );

  return (
    <Modal
      onClose={handleCropVarietyListModal}
      isCloseButtonOption={true}
      title={language.cropVarietyModalHeading}
      isOpen={isOpen}
      children={UiModal}
    />
  );
};

export default SelectVarietyModal;
