import React, { Suspense, lazy } from 'react';
import Styles from './style.module.css';
import { Box } from '@mui/material';
import { RakeListProps } from './../../hooks/RakeList/types';
import MuiSkeleton from '../../MuiComponents/Skeleton';

const Header = lazy(() => import('./Header/Header'));
const LocationContainer = lazy(
  () => import('./LocationContainer/LocationContainer'),
);
const NearByRakeCard = lazy(() => import('./NearByRakeCard/NearByRake'));
const BottomHeading = lazy(() => import('./BottomHeading/BottomHeading'));
const CurrentRakeCard = lazy(() => import('./CurrentRake/CurrentRakeCard'));
const LocationModal = lazy(() => import('./LocationAccess/LocationModal'));

export default function RakeListView(props: RakeListProps) {
  const {
    onPressBack,
    language,
    onContactUs,
    currentRakeCount,
    loadMoreRakeLoading,
    nearRakeCount,
    rakeLoading,
    onLoadMoreNearRake,
    nearRakeData,
    onClickChangeLocation,
    langCode,
    currentLocation,
    onClickChangeModal,
    locationModalLoading,
    onClickGetGpsLocation,
    isLocationModal,
    onClickRakeCard,
  } = props;
  return (
    <Box
      className={Styles.container}
      bgcolor={'background.body'}
      boxSizing={'border-box'}
    >
      <Suspense
        fallback={
          <MuiSkeleton sx={{ height: 140, width: '100%', marginBottom: 8 }} />
        }
      >
        <Header
          onContactUs={onContactUs}
          onPressBack={onPressBack}
          language={language}
        />
        <LocationContainer
          language={language}
          currentLocation={currentLocation}
          onClickChangeModal={onClickChangeModal}
        />
      </Suspense>
      <Suspense
        fallback={
          <MuiSkeleton
            sx={{
              height: 128,
              width: 'calc(100% - 32px)',
              borderRadius: 8,
              marginBottom: 8,
              alignSelf: 'center',
            }}
            itemNumber={8}
          />
        }
      >
        <CurrentRakeCard
          rakeLoading={rakeLoading}
          currentRakeCount={currentRakeCount}
          language={language}
          currentLocation={currentLocation}
          onClickRakeCard={onClickRakeCard}
        />
      </Suspense>
      <Suspense
        fallback={
          <MuiSkeleton
            sx={{
              height: 128,
              width: 'calc(100% - 32px)',
              borderRadius: 8,
              marginBottom: 8,
              alignSelf: 'center',
            }}
          />
        }
      >
        <NearByRakeCard
          loadMoreRakeLoading={loadMoreRakeLoading}
          onLoadMoreNearRake={onLoadMoreNearRake}
          nearRakeData={nearRakeData}
          rakeLoading={rakeLoading}
          nearRakeCount={nearRakeCount}
          langCode={langCode}
          language={language}
          onClickRakeCard={onClickRakeCard}
        />
      </Suspense>
      <Suspense
        fallback={
          <MuiSkeleton
            sx={{
              height: 32,
              width: 'calc(100% - 32px)',
              borderRadius: 8,
              marginBottom: 8,
              alignSelf: 'center',
            }}
          />
        }
      >
        <BottomHeading language={language} />
        <LocationModal
          language={language}
          isLocationModal={isLocationModal}
          onClickGetGpsLocation={onClickGetGpsLocation}
          locationModalLoading={locationModalLoading}
          onClickChangeModal={onClickChangeModal}
          onClickChangeLocation={onClickChangeLocation}
        />
      </Suspense>
    </Box>
  );
}
