import React from 'react';
import Styles from './style.module.css';
import Popup from '../../../Components/Popup';
import Tabs from '../../../Components/Tabs';
import { TradePriceBreakupData } from '../../../modal/Traders/types';
import Typography from '../../../ComponentV3/Typography';
import replaceVariableInString from '../../../utils/replacevariableInLanguage';
import ReceiptLongRoundedIcon from '@mui/icons-material/ReceiptLongRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';

interface TradeDetailProps {
  PriceBreakup: TradePriceBreakupData;
  handleChangeTab?: (id: string) => void;
  activeTab?: string;
  brokeragePopup: boolean;
  handleBrokeragePopup: () => void;
  financingPopup: boolean;
  handleFinancingPopup: () => void;
  netPopup: boolean;
  handleNetPopup: () => void;
  language?: { [key: string]: any };
  discountCardBgBig: string;
  discountFeeSmiley: string;
  magicIcon: string;
}

const InfoIcon = () => <InfoRoundedIcon fontSize="small" />;

const InfoIconMedium = () => (
  <InfoRoundedIcon htmlColor="#2973CC" sx={{ height: 20, width: 20 }} />
);

const PriceSectionBreakDown = (props: TradeDetailProps) => {
  const {
    PriceBreakup,
    activeTab,
    brokeragePopup,
    handleBrokeragePopup,
    financingPopup,
    handleFinancingPopup,
    netPopup,
    handleNetPopup,
    language,
    discountCardBgBig,
    discountFeeSmiley,
    magicIcon,
  } = props;

  const TabsData = {
    tab1: PriceBreakup?.price_breakup_buyer,
    tab2: PriceBreakup?.price_breakup_grn,
  };
  const renderTextWithColors = ({ text, replaceText }) => {
    if (!text) {
      return;
    }
    const splitText = text.split(/{|}/);

    return splitText.map((item, index) => {
      if (item.startsWith('(') && item.endsWith(')')) {
        return (
          <span key={'buyerDetailProceDetailColorText' + index}>
            {replaceText}
          </span>
        );
      }
      return <>{item}</>;
    });
  };

  const replaceTwo = ({ text, replaceText1, replaceText2 }) => {
    if (!text) {
      return;
    }
    const splitText = text.split(/{|}/);

    return splitText.map((item, index) => {
      if (item.startsWith('(') && item.endsWith(')')) {
        return (
          <span key={'buyerDetailProceDetailText' + index}>{replaceText1}</span>
        );
      } else if (item.startsWith('*') && item.endsWith('*')) {
        return (
          <span key={'buyerDetailProceDetailText' + index}>{replaceText2}</span>
        );
      }
      return <>{item}</>;
    });
  };

  const currentData = TabsData[activeTab];

  const discountCard = () => {
    return (
      <div
        className={Styles.discountCard}
        style={{
          backgroundImage: `url(${discountCardBgBig})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className={Styles.smiley}>
          <img
            src={discountFeeSmiley}
            height={'100%'}
            width={'100%'}
            alt="Discount Fee Smiley"
            loading="eager"
          />
        </div>
        <div className={Styles.discountCardText}>
          <Typography level="title-sm" sx={{ color: '#B2852F' }}>
            {replaceVariableInString(
              currentData?.discount_in_percent,
              language?.savingAmount,
            )}
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <div className={Styles.compWrapper}>
      <div className={Styles.infoBar}>
        <div style={{ alignSelf: 'center' }}>
          <img
            src={magicIcon}
            height={20}
            width={21}
            alt="Magic Icon"
            style={{ minWidth: 20 }}
            loading="eager"
          />
        </div>
        <div className={Styles.disclaimer}>
          {activeTab === 'tab1'
            ? replaceTwo({
                text: language?.buyerTermToolTip,
                replaceText1: `${PriceBreakup.repayment_time.value} ${PriceBreakup.repayment_time.unit}`,
                replaceText2: `${currentData?.brokerage_price} ${currentData?.brokerage_price_unit}`,
              })
            : replaceTwo({
                text: PriceBreakup?.price_breakup_grn?.discounted_fee
                  ? language?.discountedGrnTermToolTip
                  : language?.grnTermToolTip,
                replaceText1: `${currentData?.brokerage_price} ${currentData?.brokerage_price_unit}`,
                replaceText2: `${
                  currentData?.discounted_fee || currentData?.farmart_fee || 0
                }%`,
              })}
        </div>
      </div>
      <div className={Styles.breakDownList}>
        <div className={Styles.listItem}>
          <div className={Styles.leftItem}>{language?.grossPrice}</div>
          <div className={Styles.rightItem}>
            {currentData?.gross_price} {currentData?.gross_price_unit}
          </div>
        </div>
        <div className={Styles.listItem}>
          <div className={Styles.leftItem}>{language?.cashDiscount}</div>
          <div className={Styles.rightItem}>{currentData?.cash_discount}%</div>
        </div>
        <div className={Styles.listItem}>
          <div className={Styles.leftItem}>{language?.bagDeduction}</div>
          <div className={Styles.rightItem}>
            {currentData?.bag_deduction} {currentData?.bag_deduction_unit}
          </div>
        </div>
        <div className={Styles.listItem}>
          <div className={Styles.leftItem}>{language?.unloadingCharges}</div>
          <div className={Styles.rightItem}>
            {currentData?.unloading_price} {currentData?.unloading_price_unit}
          </div>
        </div>
        <div className={Styles.netBuyerPrice}>
          <div className={Styles.leftItem}>{language?.buyerNetPrice}</div>
          <div className={Styles.rightItem}>
            {currentData?.net_buyer_price} {currentData?.net_buyer_price_unit}
          </div>
        </div>
        <div className={Styles.listItem}>
          <div className={Styles.leftItem}>
            {language?.brokerage}
            <label
              className={Styles.infoIconContainer}
              onClick={() => handleBrokeragePopup()}
              data-testid="brokerageInfoIconWrapper"
            >
              <InfoIcon />
            </label>
            <Popup
              show={brokeragePopup}
              onClose={() => {
                handleBrokeragePopup();
              }}
              data={{
                title: language?.brokeragePopUpTitle,
                message: renderTextWithColors({
                  text: language?.brokeragePopUpSubHeading,
                  replaceText: `${currentData?.brokerage_price} ${currentData?.brokerage_price_unit}`,
                }),
                buttonTitle: language?.popUpOkayButton,
              }}
            />
          </div>
          <div className={Styles.rightItem}>
            {currentData?.brokerage_price} {currentData?.brokerage_price_unit}
          </div>
        </div>
        <div className={Styles.listItem}>
          <div className={Styles.leftItem}>
            {language?.financingCost}
            <label
              className={Styles.infoIconContainer}
              onClick={() => handleFinancingPopup()}
              data-testid="financingInfoIconWrapper"
            >
              <InfoIcon />
            </label>
            <Popup
              show={financingPopup}
              onClose={() => handleFinancingPopup()}
              data={{
                title: language?.financePopUpTitle,
                message: renderTextWithColors({
                  text: language?.financePopUpSubHeading,
                  replaceText: currentData?.farmart_fee,
                }),
                buttonTitle: language?.popUpOkayButton,
              }}
            />
          </div>
          <div className={Styles.rightItem}>{currentData?.farmart_fee}%</div>
        </div>

        {activeTab === 'tab2' &&
        currentData?.discounted_fee &&
        currentData?.discounted_fee !== 0 &&
        currentData?.discounted_fee !== null ? (
          <div className={Styles.listItem}>
            <div className={Styles.leftItemDiscountFee}>
              {renderTextWithColors({
                text: language?.discountFee,
                replaceText: currentData?.discount_in_percent,
              })}
            </div>
            <div className={Styles.discountFeeValue}>
              <div className={Styles.rightItemFarmartFee}>
                {currentData?.farmart_fee}
              </div>
              <div className={Styles.rightItemDiscountFee}>
                {currentData?.discounted_fee}
                {currentData?.discounted_fee_unit}
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <hr className={Styles.mainDivider} />
      <div className={Styles.listItem}>
        <div className={Styles.leftItem + ' ' + Styles.bigText}>
          {language?.netPrice}
          <label
            className={Styles.infoIconContainer}
            onClick={() => handleNetPopup()}
            data-testid="netInfoIconWrapper"
          >
            <InfoIcon />
          </label>
          <Popup
            show={netPopup}
            onClose={() => handleNetPopup()}
            data={{
              title: language?.netPricePopUpTitle,
              message: language?.netPricePopUpSubHeading,
              message2: language?.netPricePopUpSubHeading2,
              buttonTitle: language?.popUpOkayButton,
            }}
          />
        </div>
        <div className={Styles.rightItem + ' ' + Styles.netPriceText}>
          {currentData?.net_price} {currentData?.net_price_unit}
        </div>
      </div>
      {activeTab === 'tab2' &&
        currentData?.discounted_fee &&
        currentData?.discounted_fee !== 0 &&
        currentData?.discounted_fee !== null &&
        discountCard()}
    </div>
  );
};

const PriceDetails = (props: TradeDetailProps) => {
  const {
    PriceBreakup,
    handleChangeTab,
    activeTab,
    brokeragePopup,
    handleBrokeragePopup,
    financingPopup,
    handleFinancingPopup,
    netPopup,
    handleNetPopup,
    language,
    discountCardBgBig,
    discountFeeSmiley,
    magicIcon,
  } = props;

  return (
    <div className={Styles.wrapper}>
      <div className={Styles.titleBar}>
        <div className={Styles.iconWrapper}>
          <ReceiptLongRoundedIcon
            sx={{ color: '#0E1E40', height: 20, width: 20 }}
          />
        </div>
        <div className={Styles.headingText}>{language?.priceDetails}</div>
      </div>
      <div className={Styles.detailsWrapper}>
        <div className={Styles.detailsContainer}>
          <div
            style={{
              padding: '0px 12px',
            }}
          >
            <Tabs
              title1={language?.buyerTerms}
              title2={language?.grnTerms}
              active={activeTab}
              handleChange={handleChangeTab}
            />
          </div>
          <PriceSectionBreakDown
            PriceBreakup={PriceBreakup}
            handleChangeTab={handleChangeTab}
            activeTab={activeTab}
            brokeragePopup={brokeragePopup}
            handleBrokeragePopup={handleBrokeragePopup}
            financingPopup={financingPopup}
            handleFinancingPopup={handleFinancingPopup}
            netPopup={netPopup}
            handleNetPopup={handleNetPopup}
            language={language}
            discountCardBgBig={discountCardBgBig}
            discountFeeSmiley={discountFeeSmiley}
            magicIcon={magicIcon}
          />
        </div>
      </div>
      <div className={Styles.infoBar2}>
        <InfoIconMedium />
        <div className={Styles.disclaimer}>{language?.priceWarning}</div>
      </div>
    </div>
  );
};

export default PriceDetails;
