/* This code snippet is a React functional component named `App` that serves as the main entry point
for the application. Here's a breakdown of what the code is doing: */
import React, { useEffect, useState, Suspense } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
/* The line `import { Route, Routes, useLocation } from "react-router-dom";` is importing specific
components and hooks from the `react-router-dom` library. Here's a breakdown of what each import is
doing: */
import { Route, Routes, useLocation } from 'react-router-dom';
/* The line `import useAuth from "./hooks/auth/useAuth";` is importing a custom hook named `useAuth`
from a specific file path within the project structure. This custom hook is likely responsible for
handling authentication-related logic and state management within the React application. By
importing `useAuth`, the `App` component can utilize the functionality provided by this hook to
check if a user is logged in, manage authentication status, and potentially perform other
authentication-related tasks. */
import useAuth from './hooks/auth/useAuth';
/* The line `import applicationRoutes from "./routes/router";` is importing a module or file named
`router` from the relative path `"./routes"` within the project structure. This import statement is
likely used to bring in a collection of route configurations or definitions for the React
application. */
import applicationRoutes from './routes/router';
/* The line `import branch from "branch-sdk";` is importing the `branch` object from the "branch-sdk"
library. This import statement allows the React application to utilize the functionalities provided
by the Branch SDK, which is typically used for deep linking and referral tracking within mobile
applications. By importing `branch`, the application can initialize and interact with the Branch SDK
to handle deep linking features and track user referrals effectively. */
import branch from 'branch-sdk';
/* The line `import { useMoengage, addUniqueId } from "./utils/moengage";` is importing two functions,
`useMoengage` and `addUniqueId`, from a file located at `"./utils/moengage"`. These functions are
likely related to integrating MoEngage, a customer engagement platform, into the React application. */
import { useMoengage, addUniqueId } from './utils/moengage';

/* The line `import { getUserProfileFromStorage } from "./modal/auth/auth.model";` is importing a
function named `getUserProfileFromStorage` from a file located at `"./modal/auth/auth.model"`. This
function is likely responsible for retrieving the user profile data from some kind of storage, such
as local storage or session storage, within the application. */
import { getUserProfileFromStorage } from './modal/auth/auth.model';
/* The line `import * as Sentry from "@sentry/react";` is importing the Sentry SDK for error monitoring
and reporting in the React application. Sentry is a tool that helps developers track, prioritize,
and fix issues in real-time. By importing Sentry in this way, the `Sentry` object is made available
for use within the `App` component. Additionally, the `Sentry.withProfiler(App)` higher-order
component is wrapping the `App` component, enabling performance profiling capabilities provided by
Sentry. This allows for monitoring and optimizing the performance of the application. */
import * as Sentry from '@sentry/react';
/* The line `import BottomNavigationBar from "./utils/bottomNavBar";` is importing a component named
`BottomNavigationBar` from a file located at `"./utils/bottomNavBar"`. This component is likely
responsible for rendering the bottom navigation bar UI element in the application. */
import BottomNavigationBar from './utils/bottomNavBar';
import useAuthenticationChecker from './utils/authChecker';
import { apiEndPoints } from './constant/apiEndPoints';
import { userType } from './constant/enums/userType';
import { RootState } from './store';
import { setUserPersona } from './redux/slices/personaSlice';
import MuiSkeleton from './MuiComponents/Skeleton';
import { Box } from '@mui/joy';

function App() {
  /* The code snippet you provided is setting up various hooks, state variables, and side effects
  within the `App` component of a React application. Here's a breakdown of what each part of the
  code is doing: */
  const { checkingIsLoggedIn, isLoggedIn } = useAuth();
  const location = useLocation();
  const dispatch = useDispatch();
  const addId = 'CS2M99BHDFVE4TH87HIUXKP4';
  useMoengage(addId, +import.meta.env.VITE_MOENGAGE_DEBUG_MODE);
  const user = getUserProfileFromStorage();
  const loggedIn = JSON.parse(localStorage.getItem('loged'));
  const [isWebView, setIsWebView] = useState(false);
  const [modalVisible, setShowModalVisible] = useState(false);
  const [isNavBarVisibleForPath, setIsNavBarVisibleForPath] = useState(true);
  const { AuthenticationCheckerAndApiCaller } = useAuthenticationChecker();
  const [isRetailer, setIsRetailer] = useState(false);
  const store = useStore();
  const [userSelectedPersona, setUserSelectedPersona] = useState(
    useSelector((state: RootState) => state.persona.userSelectedPersona),
  );
  /* The `useEffect(() => {
      branch.init("key_live_pk6sb0nWjlo8i4IEpdwSvepfErlDZ0yB");
    }, []);` code snippet is utilizing the `useEffect` hook in a React functional component to
  initialize the Branch SDK with a specific key. Here's a breakdown of what this code is doing: */
  useEffect(() => {
    branch.init('key_live_pk6sb0nWjlo8i4IEpdwSvepfErlDZ0yB');
  }, []);

  useEffect(() => {
    if (loggedIn) {
      fetchMerchantProfile();
    }
  }, [userSelectedPersona]);

  useEffect(() => {
    if (
      JSON.parse(localStorage?.getItem('profileSelected')) ===
        userType['retailer'] ||
      JSON.parse(localStorage?.getItem('profileSelected')) === null
    ) {
      setIsRetailer(true);
    } else {
      setIsRetailer(false);
    }
  }, [JSON.parse(localStorage?.getItem('profileSelected'))]);

  useEffect(() => {
    user?.mobile_number && addUniqueId(user?.mobile_number);
  }, [user?.mobile_number]);

  useEffect(() => {
    // Logic to detect if web view is open in a mobile app
    const userAgent = navigator?.userAgent?.toLowerCase();
    setIsWebView(userAgent?.includes('wv'));

    return () => {
      setIsWebView(false);
    };
  }, []);

  const handlePersonaChange = (newPersona: string) => {
    dispatch(setUserPersona(newPersona));
  };

  async function fetchMerchantProfile() {
    try {
      const queryParameter = {};
      const response = await AuthenticationCheckerAndApiCaller({
        uri: apiEndPoints?.getMerchantProfile,
        requestType: 'post',
        payload: queryParameter,
      });
      if (response?.data?.status) {
        setIsRetailer(
          response?.data?.data?.merchant_occupation_id === userType['retailer'],
        );
        handlePersonaChange(response?.data?.data?.merchant_occupation_id);
      }
    } catch (error) {
      console.error(error);
    }
  }

  /* The `useEffect` hook you provided is responsible for updating the state variable
  `isNavBarVisibleForPath` based on the current `location.pathname` value. Here's a breakdown of
  what it does: */
  useEffect(() => {
    if (
      location?.pathname === '/' ||
      location?.pathname === '/community' ||
      location?.pathname === '/trades' ||
      location?.pathname === '/trades/mySauda' ||
      (location?.pathname === '/shopPromotion' && isRetailer)
    ) {
      setIsNavBarVisibleForPath(true);
    } else {
      setIsNavBarVisibleForPath(false);
    }
  }, [location?.pathname, isRetailer]);

  /**
   * The above function uses a MutationObserver to track changes in the DOM and show or hide a modal
   * component based on the presence of an element with the id "modalComponentVisible".
   * @param mutationsList - The `mutationsList` parameter in the `callback` function of the `useEffect`
   * hook is an array of `MutationRecord` objects. Each `MutationRecord` represents a single mutation
   * that occurred on the observed element or its descendants. It contains information about the type
   * of mutation, the target element
   * @param observer - The `observer` parameter in the `callback` function is typically an instance of
   * `MutationObserver` which is used to observe changes in the DOM and trigger a callback function
   * when a specific type of mutation occurs. In this case, the `callback` function is observing
   * mutations related to child elements being added
   */
  useEffect(() => {
    const callback = function (mutationsList, observer) {
      for (const mutation of mutationsList) {
        if (mutation?.type === 'childList') {
          if (document?.getElementById('modalComponentVisible')) {
            setShowModalVisible(true);
          } else {
            setShowModalVisible(false);
          }
        }
      }
    };
    const config = { childList: true, subtree: true };
    // Select the target node
    const targetNode = document?.body;
    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);
    // Start observing the target node for configured mutations
    observer.observe(targetNode, config);
    return () => {
      observer.disconnect();
    };
  }, []);

  // Conditional rendering for the bottom navigation bar
  const renderBottomNav =
    isLoggedIn &&
    !checkingIsLoggedIn &&
    !isWebView &&
    !modalVisible &&
    isNavBarVisibleForPath;

  function getResponsiveStyles() {
    return {
      '@media (max-width: 480px)': {
        width: '100vw',
      },

      '@media (min-width: 481px) and (max-width: 767px)': {
        width: '100vw',
      },

      '@media (min-width: 768px) and (max-width: 1024px)': {
        width: '100vw',
      },

      '@media (min-width: 1025px) and (max-width: 1280px)': {
        width: '30vw',
        margin: '0vw 35vw',
      },

      '@media (min-width: 1281px)': {
        width: '30vw',
        margin: '0vw 35vw',
      },
    };
  }

  return (
    /* This part of the code snippet within the `App` component is responsible for rendering the main
    content of the application along with handling routes and conditionally rendering the bottom
    navigation bar based on certain criteria. */
    <Suspense
      fallback={
        <Box
          sx={{
            height: '100%',
            gap: 8,
            display: 'flex',
            flexDirection: 'column',
            boxSizing: 'border-box',
            ...getResponsiveStyles(),
            padding: 8,
          }}
        >
          <MuiSkeleton
            sx={{
              height: 100,
              width: '100%',
              borderRadius: 8,
            }}
            itemNumber={8}
          />
        </Box>
      }
    >
      <div className="app-container">
        {/* handling routes */}
        <Routes>
          {applicationRoutes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              Component={route.component}
            >
              {route.routes &&
                route.routes.map((subRoute) => (
                  <Route
                    key={subRoute.path}
                    path={subRoute.path}
                    Component={subRoute.component}
                  />
                ))}
            </Route>
          ))}
        </Routes>
        {/* Render bottom navigation bar if user is logged in*/}
        {renderBottomNav && <BottomNavigationBar isRetailer={isRetailer} />}
      </div>
    </Suspense>
  );
}

export default Sentry.withProfiler(App);
