import { Box } from '@mui/joy';
import React from 'react';
import MuiAlert from '../../../ComponentsV3/MuiAlert';
import InfoRounded from '@mui/icons-material/InfoRounded';
import MuiButton from '../../../ComponentsV3/MuiButton';

/* The `interface DownloadAppProps` is defining the props that the `DownLoadAppBox` component expects
to receive. */
interface DownloadAppProps {
  language: { [key: string]: string };
  onClickDownloadApp: () => void;
}

export default function DownLoadAppBox(props: DownloadAppProps) {
  /* `const { language, onClickDownloadApp } = props;` is a destructuring assignment in JavaScript. In
  this case, it is extracting the `language` and `onClickDownloadApp` properties from the `props`
  object passed to the `DownLoadAppBox` component. */
  const { language, onClickDownloadApp } = props;
  return (
    <Box
      border={1}
      display={'flex'}
      flex={1}
      margin={'80px 16px 0px 16px'}
      borderRadius={8}
      borderColor={'neutral.outlinedBorder'}
      bgcolor={'background.surface'}
      gap={'12px'}
      padding={'12px'}
      flexDirection={'column'}
    >
      <MuiAlert
        size="md"
        style={{ display: 'flex', flex: 1, borderRadius: 8 }}
        startDecorator={<InfoRounded data-testid={'infoRoundedIconTestId'} />}
        variant="soft"
        color="warning"
      >
        {language?.webAlertText}
      </MuiAlert>
      <MuiButton
        size="lg"
        color="primary"
        variant="solid"
        onClick={onClickDownloadApp}
      >
        {language?.downloadAppButton}
      </MuiButton>
    </Box>
  );
}
