import { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DocumentUpload } from './types';
import UploadMultipleFiles from '../../modal/Traders/models/UpdateMultipleFile';
import Events from '../../constant/MoengageEvents';
import { trackEvent } from '../../utils/moengage';
import DocumentType from '../../constant/enums/docType';
import DocumentStatus from '../../constant/docStatusEnums';
import imageCompress from '../../utils/imageCompress';
import { usePlatfromData } from '../CrossPlatformActionHandler/platformChecker';
import { unVerifiedRoutes } from '../../constant/unVerifiedRoutes';
import { loginAsGuest } from '../../modal/auth/auth.model';
import { useAuth } from '../../context/login';
import DispatchDocumentEnums from '../../constant/enums/dispatchDocumentEnums';
import saudaTypeEnum from '../../constant/enums/saudaTypeEnum';
import { getUploadDocAssets } from '../../constant/imageUrls/';
import { getLanguageService } from './../../utils/getLanguageService';
import { languageUrls } from '../../constant/languageUrls/index';

export default function UseDocumentUpload(): DocumentUpload {
  const { state } = useLocation();
  const navigate = useNavigate();
  const platformData = usePlatfromData();
  const auth = useAuth();
  const userLogedIn = localStorage.getItem('loged');
  const fileInputRef = useRef(null);
  const langId = JSON.parse(localStorage.getItem('userLanguage'));
  const uploadDocAssets = getUploadDocAssets(langId?.langCode);
  const [docType, setDocType] = useState(
    state?.docType || JSON.parse(sessionStorage.getItem('docType')),
  );
  const [showDocumentUploadAlert, setShowDocumentUploadAlert] = useState(
    state?.folderStatusId === DocumentStatus.get('documentRejected'),
  ); //show/hide Alert
  const [selectedDocument, setSelectedDocument] = useState<any>(null);
  const [initialPageLoading, setInitialPageLoading] = useState<boolean>(true);
  const [showCancelUploadModal, setShowCancelUploadModal] = useState(false);
  const [showDocumentUploadFooter, setShowDocumentUploadFooter] = useState(
    !state?.folderStatusId ||
      state?.folderStatusId === DocumentStatus.get('documentRejected'),
  ); //change this value to show/hide document footer
  const saudaDetailData = state?.saudaDetailData;
  const [guestLoginAttempts, setGuestLoginAttempts] = useState<number>(0);
  const [isError, setIsError] = useState<boolean>(false);
  const [oldDocs, setOldDocs] = useState(
    (sessionStorage.getItem(DocumentType.get(docType)) !== null
      ? getOldDocuments(docType)
      : state?.fileList) || [],
  );
  const [newDocs, setNewDocs] = useState(getDocuments(docType) || []);
  const [updatedDocs, setUpdatedDocs] = useState<any>(
    getUpdatedDocuments(docType).length ? getUpdatedDocuments(docType) : [],
  );
  const [documents, setDocuments] = useState([]);
  const [pendingUploads, setPendingUploads] = useState({});
  const reUploadInputRef = useRef<(HTMLInputElement | null)[]>([]);

  const [multiFileUpload, setMultipleFileUpload] = useState(false);
  const [currentIndex, setCurrentIndex] = useState<number>(null);
  const [currentFile, setCurrentFile] = useState(null);
  const [disableDone, setDisableDone] = useState(false);
  const [deleteDocVisible, setDeleteDocVisible] = useState<boolean>(true);
  const [reuploadVisible, setReuploadVisible] = useState<boolean>(true);
  const [currentPreviewIndex, setCurrentPreviewIndex] = useState<number>(null);

  const [documentUploadToastPopup, setDocumentUploadToastPopup] =
    useState<boolean>(false);
  const [fileSizeExceededToast, setFileSizeExceededToast] =
    useState<boolean>(false);
  const [fileFormatNotSupported, setFileFormatNotSupported] =
    useState<boolean>(false);
  const [fileLengthAlert, setFileLengthAlert] = useState<boolean>(false);

  const [language, setLanguageFile] = useState({});
  let backConfirmation = '';

  useEffect(() => {
    getLanguageJson();
  }, []);

  async function getLanguageJson() {
    try {
      const url = languageUrls?.documentUpload;
      const result = await getLanguageService(url);
      setLanguageFile(result);
      backConfirmation = result?.backConfirmation;
      console.log({ result });
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  function getDocuments(docType: number) {
    if (sessionStorage.getItem(DocumentType.get(docType)) !== null) {
      const docs = JSON.parse(
        sessionStorage.getItem(DocumentType.get(docType)),
      );
      return [...docs?.newDocs];
    } else {
      return [];
    }
  }

  function getOldDocuments(docType: number) {
    if (sessionStorage.getItem(DocumentType.get(docType)) !== null) {
      const docs = JSON.parse(
        sessionStorage.getItem(DocumentType.get(docType)),
      );
      return [...docs?.oldDocs];
    } else {
      return [];
    }
  }

  function getUpdatedDocuments(docType: number) {
    if (sessionStorage.getItem(DocumentType.get(docType)) !== null) {
      const docs = JSON.parse(
        sessionStorage.getItem(DocumentType.get(docType)),
      );
      return [...docs?.updatedDocs];
    } else {
      return [];
    }
  }

  function addDocument(document) {
    setNewDocs((prevDocuments) => {
      // Check if prevDocuments is not an array or undefined
      const currentDocuments = Array.isArray(prevDocuments)
        ? prevDocuments
        : [];

      // Spread the currentDocuments and add the new document
      return [...currentDocuments, document];
    });
  }

  function addUpdatedDocument(document) {
    setUpdatedDocs((prevDocuments) => {
      const currentDocuments = Array.isArray(prevDocuments)
        ? prevDocuments
        : [];
      return [...currentDocuments, document];
    });
  }

  function setInitaialDocuments() {
    setInitialPageLoading(true);
    settingDocuments();
    setInitialPageLoading(false);
  }

  function settingDocuments() {
    setDocuments([...updatedDocs, ...newDocs, ...oldDocs]);
  }

  const settingDocumentUploadAttempt = () => {
    if (Object.keys(pendingUploads).length > 0) {
      sessionStorage.setItem('documentUploadAttempt', JSON.stringify(true));
      setDisableDone(false);
    } else {
      sessionStorage.setItem('documentUploadAttempt', JSON.stringify(false));
      setDisableDone(true);
    }
    setInitialPageLoading(false);
  };

  function onPressClosePreview() {
    trackEvent(Events?.onCreateDispatchBackButton, {
      From: 'Truck Details Preview Document',
    });
    setSelectedDocument(null);
  }

  function removeDocument() {
    trackEvent(Events?.onDeleteButtonClick, {
      From: 'Truck Details Preview Document',
    });
    if (selectedDocument?.fileData?.fileData) {
      setNewDocs((prevDocuments) =>
        prevDocuments.filter((doc) => doc.uri !== selectedDocument.uri),
      );
      setUpdatedDocs((prevDocuments) =>
        prevDocuments.filter((doc) => doc.uri !== selectedDocument.uri),
      );
      if (pendingUploads[selectedDocument?.fileName]?.oldFileDetails) {
        setOldDocs([
          ...oldDocs,
          pendingUploads[selectedDocument?.fileName]?.oldFileDetails,
        ]);
      }
      setPendingUploads((prevPendingUploads) => {
        const updatedPendingUploads = { ...prevPendingUploads };

        // Check if selectedDocument's filename is present in pendingUploads
        if (
          selectedDocument.fileName &&
          updatedPendingUploads[selectedDocument.fileName]
        ) {
          // Remove the key corresponding to selectedDocument's filename
          delete updatedPendingUploads[selectedDocument.fileName];
        }
        return updatedPendingUploads;
      });
    } else {
      setNewDocs((prevDocuments) =>
        prevDocuments.filter(
          (doc) => doc.url !== selectedDocument?.fileData?.url,
        ),
      );
      setUpdatedDocs((prevDocuments) =>
        prevDocuments.filter(
          (doc) => doc.url !== selectedDocument?.fileData?.url,
        ),
      );
      const storedDocs = JSON.parse(
        sessionStorage.getItem(DocumentType.get(docType)),
      );
      const _newDocs = storedDocs?.newDocs?.filter(
        (doc) => doc.url !== selectedDocument.fileData.url,
      );
      const _updatedDocs = storedDocs?.updatedDocs?.filter(
        (doc) => doc.url !== selectedDocument.fileData.url,
      );
      const _docs = {
        oldDocs: oldDocs,
        newDocs: _newDocs,
        updatedDocs: _updatedDocs,
      };
      sessionStorage.setItem(DocumentType.get(docType), JSON.stringify(_docs));
    }
    setSelectedDocument(null);
  }

  function preViewDocument(document, index) {
    trackEvent(Events?.onUploadedDocumentButtonClick, {
      From: 'Truck Details Upload Document',
      File_Format: document?.fileData?.type || document?.ext,
      Document_Type: DocumentType.get(docType),
    });

    document?.status_id
      ? setDeleteDocVisible(false)
      : setDeleteDocVisible(true);

    document?.status_id === 3 &&
    saudaDetailData?.sauda_creation_type !== saudaTypeEnum?.offline
      ? setReuploadVisible(true)
      : setReuploadVisible(false);
    setCurrentPreviewIndex(index);
    setSelectedDocument({
      uri: document.url || document.uri,
      fileName: document.name || document.fileName,
      fileData: document,
    });
  }

  const generateUniqueFileName = (
    filesObject,
    filesArray,
    fileName,
    oldDocs = [],
    updatedDocs = [],
  ) => {
    let newName = fileName;
    let counter = 1;

    // Function to check if the file name exists in either filesObject, filesArray, or oldDocs
    const isFileNameExists = (name) => {
      return (
        filesObject.hasOwnProperty(name) ||
        filesArray.some(
          (file) => file.file_name === name || file.fileName === name,
        ) ||
        oldDocs.some((doc) => doc.file_name === name) ||
        updatedDocs.some((doc) => doc.file_name === name)
      );
    };

    while (isFileNameExists(newName)) {
      newName = `${fileName.replace(
        /\.[^/.]+$/,
        '',
      )}_${counter}${fileName.match(/\.[^/.]+$/)}`;
      counter++;
    }

    return newName;
  };

  async function handleReUploadChange(event) {
    setSelectedDocument(null);
    setInitialPageLoading(true);
    const file = event?.target?.files[0];
    const processedFile = await processFile(file);
    if (JSON.parse(sessionStorage.getItem('fileSizeExceeded'))) {
      sessionStorage.setItem('fileSizeExceeded', JSON.stringify(false));
      setInitialPageLoading(false);
    }
    if (
      JSON.parse(sessionStorage.getItem('differentFileFormatAdditionAttempt'))
    ) {
      handleDifferentFileFormatAddition();
      sessionStorage.setItem(
        'differentFileFormatAdditionAttempt',
        JSON.stringify(false),
      );
      setInitialPageLoading(false);
    }
    if (processedFile) {
      const uniqueFileName = generateUniqueFileName(
        pendingUploads,
        updatedDocs,
        file?.name,
        oldDocs,
        newDocs,
      );
      addUpdatedDocument({
        uri: window?.URL?.createObjectURL(processedFile),
        fileName: uniqueFileName,
        fileData: processedFile,
        id: currentFile?.id,
      });
      const _pendingUpload = {
        newFileDetails: processedFile,
        oldFileDetails: currentFile,
      };
      setOldDocs((prevOldDocs) => {
        const filteredDocs = prevOldDocs.filter(
          (doc) => doc.id !== currentFile.id,
        );
        return filteredDocs;
      });
      setPendingUploads((prevPendingUploads) => ({
        ...prevPendingUploads,
        [uniqueFileName]: _pendingUpload,
      }));
    }
    event.target.value = '';
  }

  async function handleFileChange(event, index) {
    setInitialPageLoading(true);
    const files = event?.target?.files;
    if (files && files.length > 0) {
      const newFiles = Array.from(files);
      let processedFiles = await Promise.all(newFiles.map(processFile));
      processedFiles = processedFiles.filter((file) => file !== null);
      if (JSON.parse(sessionStorage.getItem('fileSizeExceeded'))) {
        sessionStorage.setItem('fileSizeExceeded', JSON.stringify(false));
        setInitialPageLoading(false);
      }
      if (
        JSON.parse(sessionStorage.getItem('differentFileFormatAdditionAttempt'))
      ) {
        handleDifferentFileFormatAddition();
        sessionStorage.setItem(
          'differentFileFormatAdditionAttempt',
          JSON.stringify(false),
        );
        setInitialPageLoading(false);
      }
      if (processedFiles.length > 0) {
        processedFiles.forEach((file) => {
          const uniqueFileName = generateUniqueFileName(
            pendingUploads,
            newDocs,
            file?.name ? file?.name : '',
            updatedDocs,
            oldDocs,
          );
          addDocument({
            uri: window?.URL?.createObjectURL(file),
            fileName: uniqueFileName,
            fileData: file,
          });
          const _pendingUpload = {
            newFileDetails: file,
            oldFileDetails: null,
          };
          setPendingUploads((prevPendingUploads) => ({
            ...prevPendingUploads,
            [uniqueFileName]: _pendingUpload,
          }));
        });
      }
    }
    event.target.value = '';
  }

  const controlBrowserNavigation = () => {
    if (JSON.parse(sessionStorage.getItem('documentUploadAttempt'))) {
      window.alert(backConfirmation);
    }
  };

  const checkFileSizeLimit = (size) => {
    if (size / (1024 * 1024) >= 10) {
      sessionStorage.setItem('fileSizeExceeded', JSON.stringify(true));
      handleFileSizeExceededToastPopup();
      return true;
    }
    return false;
  };

  function checkFileFormat() {
    sessionStorage.setItem(
      'differentFileFormatAdditionAttempt',
      JSON.stringify(true),
    );
  }

  const processFile = async (file) => {
    if (file.type.startsWith('image')) {
      const compressedImage = await compressImage(file);
      const fileSizeExceeded = checkFileSizeLimit(compressedImage?.size);
      trackEvent(Events?.techEventFileSizeUploaded, {
        docType: DispatchDocumentEnums.get(docType),
        docFormat: 'image',
        originalSize: file?.size,
        compressedSize: compressedImage?.size,
        fileSizeExceeded: fileSizeExceeded ? true : false,
      });
      if (fileSizeExceeded) {
        return null;
      }
      return compressedImage;
    } else if (file.type === 'application/pdf') {
      const fileSizeExceeded = checkFileSizeLimit(file?.size);
      trackEvent(Events?.techEventFileSizeUploaded, {
        docType: DispatchDocumentEnums.get(docType),
        docFormat: 'application/pdf',
        originalSize: file?.size,
        compressedSize: false,
        fileSizeExceeded: fileSizeExceeded ? true : false,
      });
      if (fileSizeExceeded) {
        return null;
      }
      return file;
    } else {
      trackEvent(Events?.techEventFileSizeUploaded, {
        docType: DispatchDocumentEnums.get(docType),
        docFormat: false,
        originalSize: file?.size,
        compressedSize: false,
        fileSizeExceeded: false,
      });
      checkFileFormat();
      return null;
    }
  };

  const compressImage = async (file) => {
    try {
      const compressedFile = await imageCompress(file);
      return compressedFile;
    } catch (error) {
      handleDocumentUploadToastPopup();

      return file;
    }
  };

  const handleUploadButtonClick = (from?: string, index?: number) => {
    fileInputRef.current.click();
    if (from === 'emptyState') {
      trackEvent(Events?.onDocumentUploadButtonClick, {
        From: 'Truck Details Upload Document',
      });
    } else if (from === 'addMoreFiles') {
      trackEvent(Events?.onAddMoreFilesButtonClick, {
        From: 'Truck Details Upload Document',
      });
    } else if (from === 'fileListCard') {
    }
  };

  const handleReUploadButtonClick = (
    from?: string,
    index?: number,
    file?: any,
  ) => {
    setCurrentIndex(index);
    setCurrentFile(file);
    reUploadInputRef?.current[index]?.click();
  };

  const onClickBackDocumentUpload = () => {
    trackEvent(Events?.onCreateDispatchBackButton, {
      From: 'Truck Details Upload Document',
    });
    if (platformData.platform === 'WEB' && userLogedIn) {
      auth?.setMobileNumberModalVisible(false);
    }
    if (Object.keys(pendingUploads).length > 0) {
      if (JSON.parse(sessionStorage.getItem('documentUploadAttempt'))) {
        toggleCancelUploadModal();
      } else {
        navigate(-1);
        sessionStorage.removeItem('docType');
      }
    } else {
      navigate(-1);
      sessionStorage.removeItem('docType');
    }
  };

  const doneDocumentUpload = async () => {
    const fileLength = oldDocs?.length + newDocs?.length + updatedDocs?.length;

    if (fileLength <= 10) {
      const formData = new FormData();
      formData.append('sauda_id', state?.saudaId);
      formData.append('doc_type_id', docType);
      formData.append('dispatch_id', state?.dispatchID);
      newDocs.forEach((doc) => {
        if (doc?.fileData) {
          const file = doc.fileData;
          formData.append('upload_doc', file, doc.fileName);
        }
      });
      updatedDocs.forEach((doc) => {
        if (doc?.fileData) {
          const file = doc.fileData;
          formData.append('upload_doc', file, doc.fileName);
        }
      });
      try {
        const UploadingFiles = await UploadMultipleFiles(formData);
        const _newDocs = [];
        const _updatedDocs = [];
        UploadingFiles?.data.doc_cdn_urls?.forEach((file) => {
          const fileName = file?.file_name;
          if (
            pendingUploads[fileName] &&
            pendingUploads[fileName]?.oldFileDetails
          ) {
            _updatedDocs.push({
              ...file,
              id: pendingUploads[fileName]?.oldFileDetails?.id,
            });
          } else {
            if (state.dispatchID) {
              _newDocs.push({ ...file, id: null });
            } else {
              _newDocs.push(file);
            }
          }
        });
        const docs = {
          oldDocs: oldDocs,
          newDocs: [
            ...(JSON.parse(sessionStorage.getItem(DocumentType.get(docType)))
              ?.newDocs
              ? JSON.parse(sessionStorage.getItem(DocumentType.get(docType)))
                  ?.newDocs
              : []),
            ..._newDocs,
          ],
          updatedDocs: [
            ...(JSON.parse(sessionStorage.getItem(DocumentType.get(docType)))
              ?.updatedDocs
              ? JSON.parse(sessionStorage.getItem(DocumentType.get(docType)))
                  ?.updatedDocs
              : []),
            ..._updatedDocs,
          ],
        };
        sessionStorage.setItem(DocumentType.get(docType), JSON.stringify(docs));
        if (state?.dispatchID) {
          navigate(`/trades/mySauda/createDispatch/${state?.dispatchID}`, {
            state: { saudaId: state?.saudaId, dispatchId: state?.dispatchID },
          });
        } else {
          navigate(`/trades/mySauda/createDispatch`, {
            state: { saudaId: state?.saudaId },
          });
        }
        setGuestLoginAttempts(0);
      } catch (error) {
        handleDocumentUploadToastPopup('doneButton');
        if (error?.code === 401 || error?.code === 426) {
          if (platformData.platform === 'WEB') {
            if (unVerifiedRoutes?.guestLogin?.includes(error?.path)) {
              await loginAsGuest();
              setGuestLoginAttempts((lastValue) => {
                return lastValue + 1;
              });
            } else {
              auth.setMobileNumberModalVisible(true);
            }
          }
        } else {
          setIsError(true);
          setTimeout(() => {
            navigate(`/trades/mySauda`);
          }, 5000);
        }
      }
    } else {
      setFileLengthAlert(true);
    }
    trackEvent(Events?.onDoneButtonClick, {
      From: 'Truck Details Upload Document',
    });
  };

  const toggleCancelUploadModal = () => {
    if (showCancelUploadModal) {
      trackEvent(Events?.onContinueUploadingButtonClick, {
        From: 'Truck Details Cancel Upload',
      });
    }
    setShowCancelUploadModal(!showCancelUploadModal);
  };

  const onCLickCancelUpload = () => {
    trackEvent(Events?.onCancelUploadingButtonClick, {
      From: 'Truck Details Cancel Upload',
    });
    if (state?.dispatchID) {
      navigate(`/trades/mySauda/createDispatch/${state?.dispatchID}`, {
        state: { saudaId: state?.saudaId, dispatchId: state?.dispatchID },
      });
    } else {
      navigate(`/trades/mySauda/createDispatch`, {
        state: { saudaId: state?.saudaId },
      });
    }
    sessionStorage.removeItem('docType');
  };

  const handleDocumentUploadToastPopup = (from?: string) => {
    setDocumentUploadToastPopup(true);
    setTimeout(() => {
      setDocumentUploadToastPopup(false);
      if (from === 'doneButton') {
        navigate(-1);
      }
    }, 5000);
  };

  const handleFileSizeExceededToastPopup = () => {
    setFileSizeExceededToast(true);
    setTimeout(() => {
      setFileSizeExceededToast(false);
    }, 5000);
  };

  function handleDifferentFileFormatAddition() {
    setFileFormatNotSupported(true);
    setTimeout(() => {
      setFileFormatNotSupported(false);
    }, 5000);
  }

  useEffect(() => {
    setInitaialDocuments();
  }, [oldDocs, newDocs, updatedDocs]);

  useEffect(() => {
    settingDocumentUploadAttempt();
  }, [pendingUploads]);

  useEffect(() => {
    window.addEventListener('beforeunload', function (e) {
      e.preventDefault();
      e.returnValue = '';
    });
  }, []);

  useEffect(() => {
    window.addEventListener('popstate', controlBrowserNavigation);

    return () => {
      window.removeEventListener('popstate', controlBrowserNavigation); // Remove listener on unmount
    };
  }, []);

  return {
    docType,
    onClickBackDocumentUpload,
    language,
    showDocumentUploadFooter,
    showDocumentUploadAlert,
    disableDone,
    deleteDocVisible,
    doneDocumentUpload,
    handleFileChange,
    handleUploadButtonClick,
    fileInputRef,
    documents,
    preViewDocument,
    selectedDocument,
    showCancelUploadModal,
    toggleCancelUploadModal,
    onCLickCancelUpload,
    removeDocument,
    onPressClosePreview,
    multiFileUpload,
    reUploadInputRef,
    handleReUploadChange,
    handleReUploadButtonClick,
    documentUploadToastPopup,
    handleDocumentUploadToastPopup,
    reuploadVisible,
    currentPreviewIndex,
    fileLengthAlert,
    setFileLengthAlert,
    initialPageLoading,
    saudaDetailData,
    isError,
    setIsError,
    fileSizeExceededToast,
    fileFormatNotSupported,
    uploadDocAssets,
  };
}
