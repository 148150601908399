import React from 'react';
import Styles from './style.module.css';
import Header from './Header';
import ItemDetails from './ItemDetails';
import BuyerInfo from './BuyerInfo';
import QC from './QC';
import PaymentTerms from './PaymentTerms';
import PriceDetails from './PriceDetails';
import BottomButton from './BottomButton';
import OrderDetails from './OrderDate';
import Toast from '../../ComponentV2/Toast';
import { LoginView, useAuth } from '../../context/login';

import { TradePriceBreakupData } from '../../modal/Traders/types';
interface TradeDetailProps {
  PriceBreakup: TradePriceBreakupData;
  handleChangeTab?: (id: string) => void;
  activeTab?: string;
  brokeragePopup: boolean;
  handleBrokeragePopup: () => void;
  financingPopup: boolean;
  handleFinancingPopup: () => void;
  netPopup: boolean;
  handleNetPopup: () => void;
  onClickBack: () => void;
  numberCopyToast: boolean;
  handleNumberCopyToast: () => void;
  language: { [key: string]: any };
  currentLanguageId: string;
  showRequestSauda: boolean;
  onClickRequestSauda: () => void;
  toggleRequestSaudaModal: () => void;
  quantity: number;
  handleQuantityChange: () => void;
  paymentTerms: string;
  paymentTermsType: string;
  handlePaymentTerms: () => void;
  showPaymentTermsModal: boolean;
  proceedDisabled: boolean;
  grnInfo: () => void;
  buyersInfo: () => void;
  onClosePaymentTermsModal: () => void;
  saudaRequestToastPopup: boolean;
  handleSaudaRequestToastPopup: () => void;
  proceedSaudaRequest: () => void;
  closePaymentTermsModal: () => void;
  isFocusedSaudaRequestInput: boolean;
  handleFocusSaudaRequestInput: () => void;
  handleBlurSaudaRequestInput: () => void;
  onKeyDownSaudaRequestInput: () => void;
  buyerDetailAssets: { [key: string]: string };
}

const BuyerDetails = (props: TradeDetailProps) => {
  const {
    PriceBreakup,
    handleChangeTab,
    activeTab,
    brokeragePopup,
    handleBrokeragePopup,
    financingPopup,
    handleFinancingPopup,
    netPopup,
    handleNetPopup,
    onClickBack,
    numberCopyToast,
    handleNumberCopyToast,
    language,
    currentLanguageId,
    onClickRequestSauda,
    showRequestSauda,
    toggleRequestSaudaModal,
    quantity,
    handleQuantityChange,
    paymentTerms,
    paymentTermsType,
    handlePaymentTerms,
    showPaymentTermsModal,
    proceedDisabled,
    grnInfo,
    buyersInfo,
    onClosePaymentTermsModal,
    saudaRequestToastPopup,
    handleSaudaRequestToastPopup,
    proceedSaudaRequest,
    closePaymentTermsModal,
    isFocusedSaudaRequestInput,
    handleFocusSaudaRequestInput,
    handleBlurSaudaRequestInput,
    onKeyDownSaudaRequestInput,
    buyerDetailAssets,
  } = props;
  const authProps = useAuth();
  return (
    <div className={Styles.container}>
      <Header
        PriceBreakup={PriceBreakup}
        onClickBack={onClickBack}
        language={language}
      />
      <Toast
        isError={true}
        message={language?.saudaAlreadyRequested}
        isOpen={saudaRequestToastPopup}
      />
      <Toast
        isError={false}
        message={language?.mobileNumberCopytext}
        isOpen={numberCopyToast}
      />

      {PriceBreakup && (
        <div className={Styles.scrollContainer}>
          <ItemDetails
            PriceBreakup={PriceBreakup}
            language={language}
            currentLanguageId={currentLanguageId}
          />
          <BuyerInfo PriceBreakup={PriceBreakup} language={language} />
          <PaymentTerms PriceBreakup={PriceBreakup} language={language} />
          <PriceDetails
            PriceBreakup={PriceBreakup}
            handleChangeTab={handleChangeTab}
            activeTab={activeTab}
            brokeragePopup={brokeragePopup}
            handleBrokeragePopup={handleBrokeragePopup}
            financingPopup={financingPopup}
            handleFinancingPopup={handleFinancingPopup}
            netPopup={netPopup}
            handleNetPopup={handleNetPopup}
            language={language}
            discountCardBgBig={buyerDetailAssets.discountCardBgBig}
            discountFeeSmiley={buyerDetailAssets.discountFeeSmiley}
            magicIcon={buyerDetailAssets.magicIcon}
          />
          <QC PriceBreakup={PriceBreakup} language={language} />
          <OrderDetails PriceBreakup={PriceBreakup} language={language} />
          <BottomButton
            PriceBreakup={PriceBreakup}
            numberCopyToast={numberCopyToast}
            handleNumberCopyToast={handleNumberCopyToast}
            language={language}
            showRequestSauda={showRequestSauda}
            onClickRequestSauda={onClickRequestSauda}
            toggleRequestSaudaModal={toggleRequestSaudaModal}
            quantity={quantity}
            paymentTerms={paymentTerms}
            paymentTermsType={paymentTermsType}
            showPaymentTermsModal={showPaymentTermsModal}
            handleQuantityChange={handleQuantityChange}
            handlePaymentTerms={handlePaymentTerms}
            proceedDisabled={proceedDisabled}
            grnInfo={grnInfo}
            buyersInfo={buyersInfo}
            onClosePaymentTermsModal={onClosePaymentTermsModal}
            handleBrokeragePopup={handleBrokeragePopup}
            handleFinancingPopup={handleFinancingPopup}
            handleNetPopup={handleNetPopup}
            saudaRequestToastPopup={saudaRequestToastPopup}
            handleSaudaRequestToastPopup={handleSaudaRequestToastPopup}
            proceedSaudaRequest={proceedSaudaRequest}
            closePaymentTermsModal={closePaymentTermsModal}
            isFocusedSaudaRequestInput={isFocusedSaudaRequestInput}
            handleFocusSaudaRequestInput={handleFocusSaudaRequestInput}
            handleBlurSaudaRequestInput={handleBlurSaudaRequestInput}
            onKeyDownSaudaRequestInput={onKeyDownSaudaRequestInput}
            discountCardBgBig={buyerDetailAssets.discountCardBgBig}
            discountFeeSmiley={buyerDetailAssets.discountFeeSmiley}
            magicIcon={buyerDetailAssets.magicIcon}
          />
        </div>
      )}
      <LoginView authProps={{ ...authProps }} />
    </div>
  );
};

export default BuyerDetails;
