import React from 'react';
import Styles from './style.module.css';

import WhatsAppSvg from '../../../Images/Whatsapp.svg';

const CommentContainer = ({ language, onContactUs, tradeComingSoonImage }) => {
  return (
    <div className={Styles.mainContainer} data-testid={'comment-container'}>
      <div className={Styles.imageContainer}>
        <img
          src={tradeComingSoonImage}
          alt="Trade Coming Soon"
          loading="eager"
          height={156}
          width={209}
        />
      </div>
      <div className={Styles.comingSoonText} data-testid="coming-soon-text">
        {language?.nearSoon}
      </div>

      <div
        onClick={onContactUs}
        className={Styles.helpButtonContainer}
        data-testid="connectWithUsButtonId"
      >
        <img
          className={Styles.whatsAppIcon}
          src={WhatsAppSvg as unknown as string}
          alt="whatsappIcon"
          loading="eager"
        />
        <label className={Styles.helpTextStyle}>
          {language?.connectWithUs}
        </label>
      </div>
    </div>
  );
};

export default CommentContainer;
