import { helpLineNumber } from './HelpLineNumber';

export const smsContactSupportUrl = {
  hindi: `https://wa.me/${helpLineNumber}?text=कृपया%20FarMart%20के%20बारे%20में%20अधिक%20जानकारी%20प्राप्त%20करने%20में%20हमारी%20सहायता%20करें।`,
  english: `https://wa.me/${helpLineNumber}?text=Please%20help%20%0A%0Aus%20to%20get%20more%20information%20about%20FarMart`,
};

export const freeSMSAnimations = {
  hindi:
    'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/26/8cd10857-f508-422c-8034-8594a2636de2_free-sms-hindi.riv',
  english:
    'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/26/92e82725-8861-4bb1-b435-62fb32838bde_free-sms-english.riv',
  marathi:
    'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/11/19/0cccb67e-687f-423a-a47b-22d76d3bb78f_free-sms-marathi.riv',
};
