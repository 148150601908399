import { defaultLanguageCode } from '../appLanguages';

const homeScreenAssets = new Map([
  [
    'HI',
    {
      farmartLogo2:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/18bcf225-8a86-45f2-9715-4222deaf63f2_FarMartLogo2.svg',
      distributorImage:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/10/7/a68e1821-1f85-4510-88c0-908c1105eda2_distributors.png',
      bahiKhataImage:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/10/7/30f3aec4-edc3-4990-8c83-3ec99c2088a3_bahi%20khata.png',
      cropAdvisoryImage:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/4bbc4fb2-ea24-4fc9-bd36-7cddecb62dec_CropAdvisory.svg',
      dukaanPrachaarImage:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/2e19486d-0d42-4686-b1dc-8107bec71103_DukaanPrachar.svg',
      rakeImage:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/3e9d6456-9430-4437-8cec-ebe665136c60_Rake.svg',
      quizImage:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/10/7/5cb50694-f240-4ecf-807b-e63596a86821_quiz.png',
      backgroundImage:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/4e689502-7469-48fb-bb64-ebd46d1e3257_Background.png',
      distributorCardImage:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/18ba1393-1870-4ccd-b219-f0bc432bbaaf_DistribuitorsCard.svg',
      bahiKhataCardImage:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/68057fec-b690-4164-9270-f97bf96390cf_BahiKhataCardImage.svg',
      cropAdvisoryCardImage:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/c87a2327-328a-45d9-a77e-d7cd573b83ca_CropAdvisoryCard.svg',
      dukaanPrachaarCardImage:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/8149a59f-f6b5-4bf6-8bcf-521803bfc357_DukaanPracharCard.svg',
      rakeCardImage:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/4fd1e7aa-9780-4ab7-8af0-f9c4688ae95d_RakeCard.svg',
      quizCardImage:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/b40b0536-3598-43ec-84ad-ca6b19eae3ce_QuizCard.svg',
      foodChainLogo:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/17c51dc5-5157-4191-af6a-72b0dcffd678_FoodChainLogo.svg',
      startDecorationLine:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/594ebb6b-2c29-4415-a504-5fb8f6406cc0_StartDecorationLine.svg',
      endDecorationLine:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/34b1b230-2e8f-4fa3-9d8e-b0dd610f1af2_EndDecorationLine.svg',
      whatsappIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/e9496da0-2146-43f4-b5c7-8c91fe95252b_Whatsapp.svg',
      addFilledIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/3cc14091-42b4-4248-a4e1-940c1e7405c0_AddFilledIcon.svg',
      locationIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/6d33a9ee-05f8-48ba-9121-33ff86da4f99_LocationSvg.svg',
      companyProfileIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/26/16a9030c-4301-4802-90a1-d8517d24900e_companyProfile.svg',
      clapIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/10/11/895882a9-2dfa-48d9-b03a-9bbe0df9a344_clap.png',
      clapDoneIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/10/11/0b457a82-591f-49f3-bbf6-fddb4491b6f5_clapDone.png',
    },
  ],
  [
    'EN',
    {
      farmartLogo2:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/18bcf225-8a86-45f2-9715-4222deaf63f2_FarMartLogo2.svg',
      distributorImage:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/10/7/a68e1821-1f85-4510-88c0-908c1105eda2_distributors.png',
      bahiKhataImage:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/10/7/30f3aec4-edc3-4990-8c83-3ec99c2088a3_bahi%20khata.png',
      cropAdvisoryImage:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/4bbc4fb2-ea24-4fc9-bd36-7cddecb62dec_CropAdvisory.svg',
      dukaanPrachaarImage:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/2e19486d-0d42-4686-b1dc-8107bec71103_DukaanPrachar.svg',
      rakeImage:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/3e9d6456-9430-4437-8cec-ebe665136c60_Rake.svg',
      quizImage:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/10/7/5cb50694-f240-4ecf-807b-e63596a86821_quiz.png',
      backgroundImage:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/4e689502-7469-48fb-bb64-ebd46d1e3257_Background.png',
      distributorCardImage:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/18ba1393-1870-4ccd-b219-f0bc432bbaaf_DistribuitorsCard.svg',
      bahiKhataCardImage:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/68057fec-b690-4164-9270-f97bf96390cf_BahiKhataCardImage.svg',
      cropAdvisoryCardImage:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/c87a2327-328a-45d9-a77e-d7cd573b83ca_CropAdvisoryCard.svg',
      dukaanPrachaarCardImage:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/8149a59f-f6b5-4bf6-8bcf-521803bfc357_DukaanPracharCard.svg',
      rakeCardImage:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/4fd1e7aa-9780-4ab7-8af0-f9c4688ae95d_RakeCard.svg',
      quizCardImage:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/b40b0536-3598-43ec-84ad-ca6b19eae3ce_QuizCard.svg',
      foodChainLogo:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/17c51dc5-5157-4191-af6a-72b0dcffd678_FoodChainLogo.svg',
      startDecorationLine:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/594ebb6b-2c29-4415-a504-5fb8f6406cc0_StartDecorationLine.svg',
      endDecorationLine:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/34b1b230-2e8f-4fa3-9d8e-b0dd610f1af2_EndDecorationLine.svg',
      whatsappIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/e9496da0-2146-43f4-b5c7-8c91fe95252b_Whatsapp.svg',
      addFilledIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/3cc14091-42b4-4248-a4e1-940c1e7405c0_AddFilledIcon.svg',
      locationIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/6d33a9ee-05f8-48ba-9121-33ff86da4f99_LocationSvg.svg',
      companyProfileIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/26/16a9030c-4301-4802-90a1-d8517d24900e_companyProfile.svg',
      clapIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/10/11/895882a9-2dfa-48d9-b03a-9bbe0df9a344_clap.png',
      clapDoneIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/10/11/0b457a82-591f-49f3-bbf6-fddb4491b6f5_clapDone.png',
    },
  ],
  [
    'MR',
    {
      farmartLogo2:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/18bcf225-8a86-45f2-9715-4222deaf63f2_FarMartLogo2.svg',
      distributorImage:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/10/7/a68e1821-1f85-4510-88c0-908c1105eda2_distributors.png',
      bahiKhataImage:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/10/7/30f3aec4-edc3-4990-8c83-3ec99c2088a3_bahi%20khata.png',
      cropAdvisoryImage:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/4bbc4fb2-ea24-4fc9-bd36-7cddecb62dec_CropAdvisory.svg',
      dukaanPrachaarImage:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/2e19486d-0d42-4686-b1dc-8107bec71103_DukaanPrachar.svg',
      rakeImage:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/3e9d6456-9430-4437-8cec-ebe665136c60_Rake.svg',
      quizImage:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/10/7/5cb50694-f240-4ecf-807b-e63596a86821_quiz.png',
      backgroundImage:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/4e689502-7469-48fb-bb64-ebd46d1e3257_Background.png',
      distributorCardImage:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/18ba1393-1870-4ccd-b219-f0bc432bbaaf_DistribuitorsCard.svg',
      bahiKhataCardImage:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/68057fec-b690-4164-9270-f97bf96390cf_BahiKhataCardImage.svg',
      cropAdvisoryCardImage:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/c87a2327-328a-45d9-a77e-d7cd573b83ca_CropAdvisoryCard.svg',
      dukaanPrachaarCardImage:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/8149a59f-f6b5-4bf6-8bcf-521803bfc357_DukaanPracharCard.svg',
      rakeCardImage:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/4fd1e7aa-9780-4ab7-8af0-f9c4688ae95d_RakeCard.svg',
      quizCardImage:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/b40b0536-3598-43ec-84ad-ca6b19eae3ce_QuizCard.svg',
      foodChainLogo:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/17c51dc5-5157-4191-af6a-72b0dcffd678_FoodChainLogo.svg',
      startDecorationLine:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/594ebb6b-2c29-4415-a504-5fb8f6406cc0_StartDecorationLine.svg',
      endDecorationLine:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/34b1b230-2e8f-4fa3-9d8e-b0dd610f1af2_EndDecorationLine.svg',
      whatsappIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/e9496da0-2146-43f4-b5c7-8c91fe95252b_Whatsapp.svg',
      addFilledIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/3cc14091-42b4-4248-a4e1-940c1e7405c0_AddFilledIcon.svg',
      locationIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/6d33a9ee-05f8-48ba-9121-33ff86da4f99_LocationSvg.svg',
      companyProfileIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/26/16a9030c-4301-4802-90a1-d8517d24900e_companyProfile.svg',
      clapIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/10/11/895882a9-2dfa-48d9-b03a-9bbe0df9a344_clap.png',
      clapDoneIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/10/11/0b457a82-591f-49f3-bbf6-fddb4491b6f5_clapDone.png',
    },
  ],
]);

const loginScreenAssets = new Map([
  [
    'HI',
    {
      whatsappIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/e9496da0-2146-43f4-b5c7-8c91fe95252b_Whatsapp.svg',
      farmartLogo:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/10/11/8bc1356f-c236-4b72-bced-70ed5771d386_fmtLogo.png',
      languageSelection:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/17/fd0059d4-8c02-4a13-982f-e38659b01a31_LanguageSelectIcon.svg',
      backIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/18/65232b43-9928-4f55-8520-524f92fb2c06_Back.svg',
    },
  ],
  [
    'EN',
    {
      whatsappIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/e9496da0-2146-43f4-b5c7-8c91fe95252b_Whatsapp.svg',
      farmartLogo:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/10/11/8bc1356f-c236-4b72-bced-70ed5771d386_fmtLogo.png',
      languageSelection:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/17/fd0059d4-8c02-4a13-982f-e38659b01a31_LanguageSelectIcon.svg',
      backIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/18/65232b43-9928-4f55-8520-524f92fb2c06_Back.svg',
    },
  ],
  [
    'MR',
    {
      whatsappIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/e9496da0-2146-43f4-b5c7-8c91fe95252b_Whatsapp.svg',
      farmartLogo:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/10/11/8bc1356f-c236-4b72-bced-70ed5771d386_fmtLogo.png',
      languageSelection:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/17/fd0059d4-8c02-4a13-982f-e38659b01a31_LanguageSelectIcon.svg',
      backIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/18/65232b43-9928-4f55-8520-524f92fb2c06_Back.svg',
    },
  ],
]);

const tradeScreenAssets = new Map([
  [
    'HI',
    {
      whatsappIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/e9496da0-2146-43f4-b5c7-8c91fe95252b_Whatsapp.svg',
      farmartLogo:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/10/11/8bc1356f-c236-4b72-bced-70ed5771d386_fmtLogo.png',
      languageSelection:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/17/fd0059d4-8c02-4a13-982f-e38659b01a31_LanguageSelectIcon.svg',
      backIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/18/65232b43-9928-4f55-8520-524f92fb2c06_Back.svg',
      activeSvg:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/18/9a33618d-a85f-495f-a819-b183af94662a_active.svg',
      inactiveSvg:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/18/58fdbbb8-2983-40be-976a-37cf240f0265_inactive.svg',
      searchIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/18/ea1f2bc9-1cc1-4223-97ce-de7190623135_searchIcon.svg',
      tradeComingSoon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/18/b4b14b3d-3edd-42b4-8d9e-4088d77cb505_TradeComingSoonHI.svg',
      noBuyerList:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/18/ad8f7c15-391e-4d0a-85c7-c9d0812e44e8_NoBuyerList.svg',
      noExpiredList:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/18/bd86cf87-ff62-4844-a40d-fd91f7248493_NoExpiredList.svg',
      locationIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/6d33a9ee-05f8-48ba-9121-33ff86da4f99_LocationSvg.svg',
      discountCardBgSmall:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/18/7f2b2704-220d-4685-8a49-a8f2c42f7925_DiscountCardBackgroundSmall.svg',
    },
  ],
  [
    'EN',
    {
      whatsappIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/e9496da0-2146-43f4-b5c7-8c91fe95252b_Whatsapp.svg',
      farmartLogo:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/10/11/8bc1356f-c236-4b72-bced-70ed5771d386_fmtLogo.png',
      languageSelection:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/17/fd0059d4-8c02-4a13-982f-e38659b01a31_LanguageSelectIcon.svg',
      backIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/18/65232b43-9928-4f55-8520-524f92fb2c06_Back.svg',
      activeSvg:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/18/9a33618d-a85f-495f-a819-b183af94662a_active.svg',
      inactiveSvg:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/18/58fdbbb8-2983-40be-976a-37cf240f0265_inactive.svg',
      searchIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/18/ea1f2bc9-1cc1-4223-97ce-de7190623135_searchIcon.svg',
      tradeComingSoon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/18/886347a4-a154-4446-9edf-f903cb988451_TradeComingSoonEN.svg',
      noBuyerList:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/18/ad8f7c15-391e-4d0a-85c7-c9d0812e44e8_NoBuyerList.svg',
      noExpiredList:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/18/bd86cf87-ff62-4844-a40d-fd91f7248493_NoExpiredList.svg',
      locationIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/6d33a9ee-05f8-48ba-9121-33ff86da4f99_LocationSvg.svg',
      discountCardBgSmall:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/18/7f2b2704-220d-4685-8a49-a8f2c42f7925_DiscountCardBackgroundSmall.svg',
    },
  ],
  [
    'MR',
    {
      whatsappIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/e9496da0-2146-43f4-b5c7-8c91fe95252b_Whatsapp.svg',
      farmartLogo:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/10/11/8bc1356f-c236-4b72-bced-70ed5771d386_fmtLogo.png',
      languageSelection:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/17/fd0059d4-8c02-4a13-982f-e38659b01a31_LanguageSelectIcon.svg',
      backIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/18/65232b43-9928-4f55-8520-524f92fb2c06_Back.svg',
      activeSvg:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/18/9a33618d-a85f-495f-a819-b183af94662a_active.svg',
      inactiveSvg:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/18/58fdbbb8-2983-40be-976a-37cf240f0265_inactive.svg',
      searchIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/18/ea1f2bc9-1cc1-4223-97ce-de7190623135_searchIcon.svg',
      tradeComingSoon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/11/18/1fb618f3-59a8-4305-b4da-69adcf6faa59_Marathi.png',
      noBuyerList:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/18/ad8f7c15-391e-4d0a-85c7-c9d0812e44e8_NoBuyerList.svg',
      noExpiredList:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/18/bd86cf87-ff62-4844-a40d-fd91f7248493_NoExpiredList.svg',
      locationIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/6d33a9ee-05f8-48ba-9121-33ff86da4f99_LocationSvg.svg',
      discountCardBgSmall:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/18/7f2b2704-220d-4685-8a49-a8f2c42f7925_DiscountCardBackgroundSmall.svg',
    },
  ],
]);

const buyerDetailAssets = new Map([
  [
    'HI',
    {
      discountCardBgBig:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/19/031146f2-1019-4074-b7c1-753e6ba63ff4_DiscountCardBackgroundBig.svg',
      discountFeeSmiley:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/19/7dd98b62-0860-4bdd-b821-bacf17403f0e_discountFeeSmiley.svg',
      magicIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/19/c4ced6e8-73b6-4c70-9d79-ae470b861292_magicIcon.svg',
    },
  ],
  [
    'EN',
    {
      discountCardBgBig:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/19/031146f2-1019-4074-b7c1-753e6ba63ff4_DiscountCardBackgroundBig.svg',
      discountFeeSmiley:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/19/7dd98b62-0860-4bdd-b821-bacf17403f0e_discountFeeSmiley.svg',
      magicIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/19/c4ced6e8-73b6-4c70-9d79-ae470b861292_magicIcon.svg',
    },
  ],
  [
    'MR',
    {
      discountCardBgBig:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/19/031146f2-1019-4074-b7c1-753e6ba63ff4_DiscountCardBackgroundBig.svg',
      discountFeeSmiley:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/19/7dd98b62-0860-4bdd-b821-bacf17403f0e_discountFeeSmiley.svg',
      magicIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/19/c4ced6e8-73b6-4c70-9d79-ae470b861292_magicIcon.svg',
    },
  ],
]);

const mySaudaAssets = new Map([
  [
    'HI',
    {
      noSaudaFound:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/18/ad8f7c15-391e-4d0a-85c7-c9d0812e44e8_NoBuyerList.svg',
    },
  ],
  [
    'EN',
    {
      noSaudaFound:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/18/ad8f7c15-391e-4d0a-85c7-c9d0812e44e8_NoBuyerList.svg',
    },
  ],
  [
    'MR',
    {
      noSaudaFound:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/18/ad8f7c15-391e-4d0a-85c7-c9d0812e44e8_NoBuyerList.svg',
    },
  ],
]);

const faqAssets = new Map([
  [
    'HI',
    {
      whatsappIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/e9496da0-2146-43f4-b5c7-8c91fe95252b_Whatsapp.svg',
    },
  ],
  [
    'EN',
    {
      whatsappIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/e9496da0-2146-43f4-b5c7-8c91fe95252b_Whatsapp.svg',
    },
  ],
  [
    'MR',
    {
      whatsappIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/e9496da0-2146-43f4-b5c7-8c91fe95252b_Whatsapp.svg',
    },
  ],
]);

const rakeDetailAssets = new Map([
  [
    'HI',
    {
      noRake:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/21/a665da4b-6dc1-4e9b-8ef1-abd8ae41068d_noRake.svg',
    },
  ],
  [
    'EN',
    {
      noRake:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/21/a665da4b-6dc1-4e9b-8ef1-abd8ae41068d_noRake.svg',
    },
  ],
  [
    'MR',
    {
      noRake:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/21/a665da4b-6dc1-4e9b-8ef1-abd8ae41068d_noRake.svg',
    },
  ],
]);

const uploadDocAssets = new Map([
  [
    'HI',
    {
      invoiceDoc:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/23/f0bd2610-7ca9-4121-bd5f-d8fb2d12059c_Invoice%20Doc%20Empty%20State.png',
      weightSlip:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/23/82576f8b-89df-49c8-9e61-83afb46ae3aa_Weight%20Slip%20Empty%20State.png',
    },
  ],
  [
    'EN',
    {
      invoiceDoc:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/23/f0bd2610-7ca9-4121-bd5f-d8fb2d12059c_Invoice%20Doc%20Empty%20State.png',
      weightSlip:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/23/82576f8b-89df-49c8-9e61-83afb46ae3aa_Weight%20Slip%20Empty%20State.png',
    },
  ],
  [
    'MR',
    {
      invoiceDoc:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/23/f0bd2610-7ca9-4121-bd5f-d8fb2d12059c_Invoice%20Doc%20Empty%20State.png',
      weightSlip:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/23/82576f8b-89df-49c8-9e61-83afb46ae3aa_Weight%20Slip%20Empty%20State.png',
    },
  ],
]);

const saudaDetailsAssets = new Map([
  [
    'HI',
    {
      saudaDetailsImage:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/23/0b4f1015-3fc9-4273-9e39-0e307aa12f46_saudaDetailImage.png',
      whatsappIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/e9496da0-2146-43f4-b5c7-8c91fe95252b_Whatsapp.svg',
      activeSvg:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/18/9a33618d-a85f-495f-a819-b183af94662a_active.svg',
      inactiveSvg:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/18/58fdbbb8-2983-40be-976a-37cf240f0265_inactive.svg',
      onTimeIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/26/1fafd3f5-dbfe-445a-b708-557343005752_onTimeIcon2.svg',
      magicIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/19/c4ced6e8-73b6-4c70-9d79-ae470b861292_magicIcon.svg',
    },
  ],
  [
    'EN',
    {
      saudaDetailsImage:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/23/0b4f1015-3fc9-4273-9e39-0e307aa12f46_saudaDetailImage.png',
      whatsappIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/e9496da0-2146-43f4-b5c7-8c91fe95252b_Whatsapp.svg',
      activeSvg:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/18/9a33618d-a85f-495f-a819-b183af94662a_active.svg',
      inactiveSvg:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/18/58fdbbb8-2983-40be-976a-37cf240f0265_inactive.svg',
      onTimeIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/26/1fafd3f5-dbfe-445a-b708-557343005752_onTimeIcon2.svg',
      magicIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/19/c4ced6e8-73b6-4c70-9d79-ae470b861292_magicIcon.svg',
    },
  ],
  [
    'MR',
    {
      saudaDetailsImage:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/23/0b4f1015-3fc9-4273-9e39-0e307aa12f46_saudaDetailImage.png',
      whatsappIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/6/e9496da0-2146-43f4-b5c7-8c91fe95252b_Whatsapp.svg',
      activeSvg:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/18/9a33618d-a85f-495f-a819-b183af94662a_active.svg',
      inactiveSvg:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/18/58fdbbb8-2983-40be-976a-37cf240f0265_inactive.svg',
      onTimeIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/26/1fafd3f5-dbfe-445a-b708-557343005752_onTimeIcon2.svg',
      magicIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/19/c4ced6e8-73b6-4c70-9d79-ae470b861292_magicIcon.svg',
    },
  ],
]);

const communityAssets = new Map([
  [
    'HI',
    {
      avatarIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/26/80046d66-758a-4ef7-8bc8-677a3f13577d_avatar.svg',
      addToHomeImage:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/26/cd1fd45d-a2f0-493b-b927-994d11943478_farmartAddHome.png',
      farmartLogo:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/26/577c192d-6764-4052-ba62-e625e631d232_farmart.svg',
      companyProfileIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/26/16a9030c-4301-4802-90a1-d8517d24900e_companyProfile.svg',
      userProfileIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/26/6c86068d-349d-4fec-9fe0-aebce8823746_userProfile.svg',
      whatsappIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/10/11/be8038ab-904e-48b6-8935-0b7e92b26e87_whatsapp.png',
      clapIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/10/11/895882a9-2dfa-48d9-b03a-9bbe0df9a344_clap.png',
      clapDoneIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/10/11/0b457a82-591f-49f3-bbf6-fddb4491b6f5_clapDone.png',
    },
  ],
  [
    'EN',
    {
      avatarIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/26/80046d66-758a-4ef7-8bc8-677a3f13577d_avatar.svg',
      addToHomeImage:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/26/cd1fd45d-a2f0-493b-b927-994d11943478_farmartAddHome.png',
      farmartLogo:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/26/577c192d-6764-4052-ba62-e625e631d232_farmart.svg',
      companyProfileIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/26/16a9030c-4301-4802-90a1-d8517d24900e_companyProfile.svg',
      userProfileIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/26/6c86068d-349d-4fec-9fe0-aebce8823746_userProfile.svg',
      whatsappIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/10/11/be8038ab-904e-48b6-8935-0b7e92b26e87_whatsapp.png',
      clapIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/10/11/895882a9-2dfa-48d9-b03a-9bbe0df9a344_clap.png',
      clapDoneIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/10/11/0b457a82-591f-49f3-bbf6-fddb4491b6f5_clapDone.png',
    },
  ],
  [
    'MR',
    {
      avatarIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/26/80046d66-758a-4ef7-8bc8-677a3f13577d_avatar.svg',
      addToHomeImage:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/26/cd1fd45d-a2f0-493b-b927-994d11943478_farmartAddHome.png',
      farmartLogo:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/26/577c192d-6764-4052-ba62-e625e631d232_farmart.svg',
      companyProfileIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/26/16a9030c-4301-4802-90a1-d8517d24900e_companyProfile.svg',
      userProfileIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/26/6c86068d-349d-4fec-9fe0-aebce8823746_userProfile.svg',
      whatsappIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/10/11/be8038ab-904e-48b6-8935-0b7e92b26e87_whatsapp.png',
      clapIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/10/11/895882a9-2dfa-48d9-b03a-9bbe0df9a344_clap.png',
      clapDoneIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/10/11/0b457a82-591f-49f3-bbf6-fddb4491b6f5_clapDone.png',
    },
  ],
]);

const myProfileAssets = new Map([
  [
    'HI',
    {
      languageToggleIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/10/4/dcf493d0-ccda-4dcb-b4cc-90beeebdea4a_languageSelection.svg',
    },
  ],
  [
    'EN',
    {
      languageToggleIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/10/4/dcf493d0-ccda-4dcb-b4cc-90beeebdea4a_languageSelection.svg',
    },
  ],
  [
    'MR',
    {
      languageToggleIcon:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/10/4/dcf493d0-ccda-4dcb-b4cc-90beeebdea4a_languageSelection.svg',
    },
  ],
]);
const pincodeAssets = new Map([
  [
    'HI',
    {
      noLocationImage:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/10/8/e20d28b8-30c4-42bc-8823-b3c23c01f71b_noLocationFound.png',
    },
  ],
  [
    'EN',
    {
      noLocationImage:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/10/8/e20d28b8-30c4-42bc-8823-b3c23c01f71b_noLocationFound.png',
    },
  ],
  [
    'MR',
    {
      noLocationImage:
        'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/10/8/e20d28b8-30c4-42bc-8823-b3c23c01f71b_noLocationFound.png',
    },
  ],
]);

export {
  pincodeAssets,
  myProfileAssets,
  communityAssets,
  saudaDetailsAssets,
  uploadDocAssets,
  rakeDetailAssets,
  faqAssets,
  mySaudaAssets,
  buyerDetailAssets,
  tradeScreenAssets,
  loginScreenAssets,
  homeScreenAssets,
  defaultLanguageCode,
};
