/**
 * The function `GetRequest` sends a GET request to a specified URI with provided payload and handles
 * authentication, language settings, and error responses.
 * @param {getRequestQueryParameter} props - The `props` parameter in the `GetRequest` function is an
 * object with two properties:
 * @returns The GetRequest function returns a Promise that resolves to a UnifiedResponse object. The
 * UnifiedResponse object contains two properties: "data" which holds the data fetched from the API
 * response, and "response" which holds the entire API response object.
 */
import getConfigByKeys from '../../config/config';
import { configKeys } from '../../config/types';
import handleHttpError from '../api/httpErrorMapping';
import HttpStatusCode from '../../apis/types/httpStatusCodesEnums.type';
import UnifiedResponse from '../../apis/types/unifiedResponse.type';

import { DEFAULT_LANGUAGE_ID } from '../../constant/language';
import { appLanguages } from '../../constant/appLanguages';

import {
  getAuthTokenFromStorage,
  getUserLanguageFromStorage,
  getUserProfileFromStorage,
} from '../../modal/auth/auth.model';

const { API_URL } = getConfigByKeys([configKeys.API_URL]);

interface getRequestQueryParameter {
  payload: any;
  uri: string;
}

export default async function GetRequest(
  props: getRequestQueryParameter,
): Promise<UnifiedResponse> {
  const { payload, uri } = props;
  const Auth = getAuthTokenFromStorage();
  const activeSystemLang = Intl.DateTimeFormat().resolvedOptions().locale;
  const systemLanguageId = activeSystemLang?.includes('en')
    ? appLanguages[1].backendLanguageCode
    : appLanguages[0].backendLanguageCode;

  console.log(activeSystemLang, systemLanguageId, '12121========');

  let userLanguage =
    getUserLanguageFromStorage()?.langId ||
    systemLanguageId ||
    DEFAULT_LANGUAGE_ID;
  const userProfile = getUserProfileFromStorage() || null;

  if (!userLanguage) {
    userLanguage = DEFAULT_LANGUAGE_ID;
  }

  const response = await fetch(
    `${API_URL}${uri}?${new URLSearchParams(payload)}`,
    {
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: Auth ? 'Bearer ' + Auth : null,
        language: userLanguage.toString(),
        appVersion: userProfile?.appVersion ? userProfile?.appVersion : null,
        'fmt-installation-id': userProfile?.fmtInstallationId
          ? userProfile?.fmtInstallationId
          : null,
        'fmt-device-id': userProfile?.uniqueDeviceId
          ? userProfile?.uniqueDeviceId
          : null,
      },
    },
  );

  if (response.status !== HttpStatusCode.Ok) {
    throw handleHttpError(response.status);
  }

  const data = await response.json();
  return { data, response };
}
