import { useState, useEffect } from 'react';
import { createDispatchTypes } from './types';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import getDispatchDetailsApi from '../../modal/mySauda/models/getDispatchDetails';
import { DispatchDetailsResponse, Folder } from '../../modal/mySauda/types';
import Events from '../../constant/MoengageEvents';
import { trackEvent } from '../../utils/moengage';
import DispatchDocumentEnums from '../../constant/enums/dispatchDocumentEnums';
import CreateDispatchApi from '../../modal/mySauda/models/createDispatch';
import UpdateDispatchApi from '../../modal/mySauda/models/updateDispatch';
import DocumentType from '../../constant/enums/docType';
import { usePlatfromData } from '../CrossPlatformActionHandler/platformChecker';
import { unVerifiedRoutes } from '../../constant/unVerifiedRoutes';
import { loginAsGuest } from '../../modal/auth/auth.model';
import { useAuth } from '../../context/login';
import saudaTypeEnum from '../../constant/enums/saudaTypeEnum';
import { getLanguageService } from './../../utils/getLanguageService';
import { languageUrls } from '../../constant/languageUrls/index';

export default function useCreateDispatch(): createDispatchTypes {
  const navigate = useNavigate();
  const platformData = usePlatfromData();
  const auth = useAuth();
  const userLogedIn = localStorage.getItem('loged');
  const langId = JSON.parse(localStorage.getItem('userLanguage'));
  const { dispatchId, saudaCreationType } = useParams();
  const { state } = useLocation();
  const dispatchID = dispatchId || state?.dispatchId;
  const saudaId = state?.saudaId;
  const saudaDetailData = state?.saudaDetailData;
  const [dispatchDetails, setDispatchDetails] =
    useState<DispatchDetailsResponse>(null);
  const docTypeEnums = DocumentType;

  const [dispatchWeight, setDispatchWeight] = useState<string>(
    sessionStorage.getItem('dispatchWeight')
      ? sessionStorage.getItem('dispatchWeight')
      : '',
  );
  const [truckNumber, setTruckNumber] = useState<string>(
    sessionStorage.getItem('truckNumber')
      ? sessionStorage.getItem('truckNumber')
      : '',
  );
  const [noOfBags, setNoOfBags] = useState<string>(
    sessionStorage.getItem('noOfBags')
      ? sessionStorage.getItem('noOfBags')
      : '',
  );
  const [truckDriverNumber, setTruckDriverNumber] = useState<string>(
    sessionStorage.getItem('truckDriverNumber')
      ? sessionStorage.getItem('truckDriverNumber')
      : '',
  );
  const [dispatchDetailLoading, setDispatchDetailLoading] =
    useState<boolean>(false);
  const [createDispatchToastPopup, setCreateDispatchToastPopup] =
    useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [mandiToolTipVisible, setMandiToolTipVisible] =
    useState<boolean>(false);

  const [invoiceDocument, setInvoiceDocument] = useState<Folder>(
    getDocuments(1) ? { docs: getDocuments(1) } : null,
  );
  const [weightSlipDocument, setWeightSlipDocument] = useState<Folder>(
    getDocuments(2) ? { docs: getDocuments(2) } : null,
  );
  const [mandiDoument, setMandiDocument] = useState<Folder>(
    getDocuments(3) ? { docs: getDocuments(3) } : null,
  );

  const [error, setError] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [showCancelDispatchModal, setShowCancelDispatchModal] =
    useState<boolean>(false);
  const [guestLoginAttempts, setGuestLoginAttempts] = useState<number>(0);
  const [truckErrorMessage, setTruckErrorMessage] = useState<boolean>(false);
  const [language, setLanguageFile] = useState({});
  let backConfirmationText = '';

  useEffect(() => {
    getLanguageJson();
  }, []);

  async function getLanguageJson() {
    try {
      const url = languageUrls?.createDispatchUrl;
      const result = await getLanguageService(url);
      setLanguageFile(result);
      backConfirmationText = result?.backConfirmationText;
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  function clearSessionStorage() {
    sessionStorage.removeItem('dispatchWeight');
    sessionStorage.removeItem('truckNumber');
    sessionStorage.removeItem('noOfBags');
    sessionStorage.removeItem('truckDriverNumber');
    sessionStorage.removeItem('invoice_docs');
    sessionStorage.removeItem('mandi_tax');
    sessionStorage.removeItem('weight_slip');
    sessionStorage.removeItem('createDispatchAttempt');
    sessionStorage.removeItem('documentUploadAttempt');
  }

  function getDocuments(docType: number) {
    if (sessionStorage.getItem(docTypeEnums.get(docType)) !== null) {
      const docs = JSON.parse(
        sessionStorage.getItem(docTypeEnums.get(docType)),
      );
      return [...docs?.oldDocs, ...docs?.newDocs, ...docs?.updatedDocs];
    } else {
      return null;
    }
  }

  function getUpdatedDocs(docType: number) {
    if (sessionStorage.getItem(docTypeEnums.get(docType)) !== null) {
      const docs = JSON.parse(
        sessionStorage.getItem(docTypeEnums.get(docType)),
      );
      return [...docs?.newDocs, ...docs?.updatedDocs];
    } else {
      return null;
    }
  }

  const controlBrowserNavigation = () => {
    if (
      JSON.parse(sessionStorage.getItem('createDispatchAttempt')) ||
      JSON.parse(sessionStorage.getItem('documentUploadAttempt'))
    ) {
      window.alert(backConfirmationText);
      clearSessionStorage();
      navigate(`/trades/mySauda/${saudaId}`);
    } else {
      clearSessionStorage();
    }
  };

  const onClickInput = (value: string) => {
    trackEvent(
      value === 'dispatchWeight'
        ? Events?.onDispatchedWeightInputBoxClick
        : value === 'truckNumber'
        ? Events?.onTruckNumberInputBoxClick
        : value === 'numberOfBag'
        ? Events?.onNumberOfBagInputBoxClick
        : value === 'truckDriverMobileNumber' &&
          Events?.onTruckDriverMobileNumberInputBoxClick,
      {},
    );
  };

  const setCreateDispatchAttempt = () => {
    sessionStorage.setItem('createDispatchAttempt', JSON.stringify(true));
  };

  const onChangeDispatchWeight = (event: any) => {
    const value = event?.target?.value;
    const sanitizedValue = value?.replace(/[^\d.]/g, '');
    const finalValue = sanitizedValue?.replace(/(\..*)\./g, '$1');
    setDispatchWeight(finalValue);
    sessionStorage.setItem('dispatchWeight', finalValue);
    setCreateDispatchAttempt();
  };

  const onChangeTruckNumber = (event: any) => {
    const value = event?.target?.value;
    const finalValue = value?.replace(/[^a-zA-Z0-9]/g, '');
    setTruckNumber(finalValue);
    sessionStorage.setItem('truckNumber', finalValue);
    setCreateDispatchAttempt();
  };

  const onChangeNoOfBags = (event: any) => {
    const value = event?.target?.value;
    const finalValue = value?.replace(/[^\d]/g, '');
    setNoOfBags(finalValue);
    sessionStorage.setItem('noOfBags', finalValue);
    setCreateDispatchAttempt();
  };

  const onChangeTruckDriverNumber = (event: any) => {
    const value = event?.target?.value;
    const finalValue = value?.replace(/[^\d]/g, '');
    setTruckDriverNumber(finalValue);
    sessionStorage.setItem('truckDriverNumber', finalValue);
    setCreateDispatchAttempt();
  };

  function onClickBackButton() {
    trackEvent(Events?.onBackButtonClick, {
      From: 'Truck Details Create Dispatch',
    });
    if (
      (sessionStorage.getItem('createDispatchAttempt') !== null &&
        JSON.parse(sessionStorage.getItem('createDispatchAttempt'))) ||
      JSON.parse(sessionStorage.getItem('documentUploadAttempt'))
    ) {
      setShowCancelDispatchModal(!showCancelDispatchModal);
    } else {
      if (platformData.platform === 'WEB' && userLogedIn) {
        auth?.setMobileNumberModalVisible(false);
      }
      if (
        (sessionStorage.getItem('createDispatchAttempt') !== null &&
          JSON.parse(sessionStorage.getItem('createDispatchAttempt'))) ||
        JSON.parse(sessionStorage.getItem('documentUploadAttempt'))
      ) {
        setShowCancelDispatchModal(!showCancelDispatchModal);
      } else {
        // navigate(-1)
        navigate(`/trades/mySauda/${saudaId}`);
        clearSessionStorage();
      }
      //please add route states and route address as per requirement
    }
  }

  async function getDispatchDetails() {
    setDispatchDetailLoading(true);
    try {
      const DispatchDetailspayload = {
        dispatch_id: Number(dispatchId),
        sauda_creation_type:
          saudaDetailData?.sauda_creation_type ||
          saudaCreationType ||
          saudaTypeEnum?.online,
      };
      const dispatchDetailsResponse = await getDispatchDetailsApi(
        DispatchDetailspayload,
      );
      setDispatchDetails(dispatchDetailsResponse);
      setDispatchWeight(dispatchDetailsResponse?.dispatched_weight.toString());
      setTruckNumber(dispatchDetailsResponse?.vehicle_number.toString());
      setNoOfBags(dispatchDetailsResponse?.number_of_bags.toString());
      setTruckDriverNumber(
        dispatchDetailsResponse?.truck_driver_mobile_number?.toString(),
      );
      setInvoiceDocument(
        dispatchDetailsResponse?.invoice_documents.docs?.length
          ? dispatchDetailsResponse?.invoice_documents
          : { docs: getDocuments(1) },
      );
      setWeightSlipDocument(
        dispatchDetailsResponse?.weight_slip_documents?.docs?.length
          ? dispatchDetailsResponse?.weight_slip_documents
          : { docs: getDocuments(2) },
      );
      setMandiDocument(
        dispatchDetailsResponse?.mandi_documents.docs?.length
          ? dispatchDetailsResponse?.mandi_documents
          : { docs: getDocuments(3) },
      );
      sessionStorage.setItem(
        'dispatchWeight',
        dispatchDetailsResponse?.dispatched_weight.toString(),
      );
      sessionStorage.setItem(
        'noOfBags',
        dispatchDetailsResponse?.number_of_bags.toString(),
      );
      sessionStorage.setItem(
        'truckNumber',
        dispatchDetailsResponse?.vehicle_number.toString(),
      );
      sessionStorage.setItem(
        'truckDriverNumber',
        dispatchDetailsResponse?.vehicle_number.toString(),
      );
      setDispatchDetailLoading(false);
    } catch (error) {
      if (error?.code === 401 || error?.code === 426) {
        if (platformData.platform === 'WEB') {
          if (unVerifiedRoutes?.guestLogin?.includes(error?.path)) {
            await loginAsGuest();
            setGuestLoginAttempts((lastValue) => {
              return lastValue + 1;
            });
          } else {
            auth.setMobileNumberModalVisible(true);
          }
        }
      } else {
        setIsError(true);
        setTimeout(() => {
          navigate(`/trades/mySauda`);
        }, 5000);
      }
      console.log(error);
    }
  }

  const createFileList = (documentType: number) => {
    if (DispatchDocumentEnums.get(documentType) === 'invoice') {
      return invoiceDocument?.folder_status_id ? invoiceDocument?.docs : [];
    } else if (DispatchDocumentEnums.get(documentType) === 'weightSlip') {
      return weightSlipDocument?.folder_status_id
        ? weightSlipDocument?.docs
        : [];
    } else if (DispatchDocumentEnums.get(documentType) === 'mandiTax')
      return mandiDoument?.folder_status_id ? mandiDoument?.docs : [];
  };

  const onClickUploadDocument = (
    value: number,
    documentStatus: string,
    documentType: string,
    folderStatusId: number,
  ) => {
    trackEvent(Events?.onUploadSaudaDocumentButtonClick, {
      'Document Status': documentStatus,
      'Document Type': documentType,
    });
    const fileList = createFileList(value);
    navigate('/trades/uploadDocument', {
      state: {
        docType: value,
        saudaId: state?.saudaId,
        dispatchID: dispatchID,
        fileList: fileList,
        folderStatusId: folderStatusId,
        saudaDetailData: saudaDetailData,
      },
    });
    sessionStorage.setItem('docType', JSON.stringify(value));
  };

  function validatetruckNumber() {
    const pattern = /^[A-Za-z]{2}[ -]?\w{1,3}[ -]?[A-Za-z]{1}[ -]?\d{4}$/;
    // Check if truck number matches the regex pattern
    if (!pattern.test(truckNumber)) {
      return true;
    } else {
      return false;
    }
  }

  function checkEmptyFields() {
    if (
      dispatchWeight === '' ||
      truckNumber === '' ||
      noOfBags === '' ||
      (noOfBags && noOfBags.charAt(0) === '0') ||
      invoiceDocument?.docs?.length === 0 ||
      invoiceDocument?.docs?.length === undefined ||
      weightSlipDocument?.docs?.length === 0 ||
      weightSlipDocument?.docs?.length === undefined ||
      truckDriverNumber?.length < 10 ||
      (truckDriverNumber && truckDriverNumber.charAt(0) === '0')
    ) {
      setError(true);
    } else {
      setError(false);
    }
  }

  const toggleCancelDispatchModal = () => {
    if (showCancelDispatchModal) {
      trackEvent(Events?.onContinueDispatchButtonClick, {});
    }
    setShowCancelDispatchModal(!showCancelDispatchModal);
  };

  const onClickSubmitButton = async () => {
    trackEvent(Events?.onCreateDispatchSubmitButtonClick, {});
    const validatetruckNumberIsCorrect = validatetruckNumber();
    if (!validatetruckNumberIsCorrect) {
      setTruckErrorMessage(false);
      try {
        setSubmitLoading(true);
        if (dispatchID) {
          const payload = {
            sauda_id: Number(saudaId),
            dispatch_id: Number(dispatchID),
            invoice_documents:
              getUpdatedDocs(1) === null ? [] : getUpdatedDocs(1),
            mandi_documents:
              getUpdatedDocs(3) === null ? [] : getUpdatedDocs(3),
            weight_slip_documents:
              getUpdatedDocs(2) === null ? [] : getUpdatedDocs(2),
          };
          const response = await UpdateDispatchApi(payload);
          clearSessionStorage();
          setGuestLoginAttempts(0);
          navigate(`/trades/mySauda/${saudaId}`);
        } else {
          const payload = {
            sauda_id: Number(saudaId),
            vehicle_number: truckNumber.toString(),
            dispatched_weight: Number(dispatchWeight),
            number_of_bags: Number(noOfBags),
            truck_driver_mobile_number: Number(truckDriverNumber),
            invoice_documents:
              invoiceDocument?.docs === null ? [] : invoiceDocument?.docs,
            weight_slip_documents:
              weightSlipDocument?.docs === null ? [] : weightSlipDocument?.docs,
            mandi_documents:
              mandiDoument?.docs === null ? [] : mandiDoument?.docs,
          };
          const response = await CreateDispatchApi(payload);
          clearSessionStorage();
          setGuestLoginAttempts(0);
          setSubmitLoading(false);
          navigate(`/trades/mySauda/${saudaId}`);
        }
      } catch (error) {
        setSubmitLoading(false);
        if (error?.code === 401 || error?.code === 426) {
          if (platformData.platform === 'WEB') {
            if (unVerifiedRoutes?.guestLogin?.includes(error?.path)) {
              await loginAsGuest();
              setGuestLoginAttempts((lastValue) => {
                return lastValue + 1;
              });
            } else {
              auth.setMobileNumberModalVisible(true);
            }
          }
        } else {
          handleCreateDispatchToastPopup();
        }
        console.log(error);
      } finally {
        setSubmitLoading(false);
      }
    } else {
      setTruckErrorMessage(true);
      setIsError(true);
    }
  };

  const onClickCancelDispatch = () => {
    trackEvent(Events?.onCancelDispatchButtonClick, {});
    setShowCancelDispatchModal(!showCancelDispatchModal);
    // navigate(-1);
    navigate(`/trades/mySauda/${saudaId}`);
    clearSessionStorage();
  };

  const handleCreateDispatchToastPopup = (from?: string) => {
    setCreateDispatchToastPopup(true);
    clearSessionStorage();
    setTimeout(() => {
      if (from === 'getDispatchDetails') {
        navigate('/trades');
      } else {
        navigate('/trades/mySauda');
      }
      setCreateDispatchToastPopup(false);
    }, 5000);
  };

  function onClickMandiToolTip(event) {
    event.stopPropagation();
    setMandiToolTipVisible(!mandiToolTipVisible);
  }

  function onPopStateHandler() {
    controlBrowserNavigation();
    onClickBackButton();
  }

  useEffect(() => {
    window.onpopstate = onPopStateHandler;
  }, []);

  useEffect(
    function () {
      if (dispatchID) {
        getDispatchDetails();
      }
    },
    [userLogedIn],
  );

  useEffect(
    function () {
      checkEmptyFields();
    },
    [
      dispatchWeight,
      truckNumber,
      noOfBags,
      invoiceDocument,
      weightSlipDocument,
      truckDriverNumber,
    ],
  );

  useEffect(
    function () {
      if (platformData.platform === 'web') {
        !userLogedIn && auth?.setMobileNumberModalVisible(true);
      }
    },
    [userLogedIn],
  );

  return {
    language,
    onClickBackButton,
    dispatchDetails,
    dispatchWeight,
    truckNumber,
    noOfBags,
    onChangeDispatchWeight,
    onChangeTruckNumber,
    onChangeNoOfBags,
    dispatchDetailLoading,
    onClickInput,
    onClickUploadDocument,
    onClickSubmitButton,
    error,
    setError,
    invoiceDocument,
    weightSlipDocument,
    mandiDoument,
    showCancelDispatchModal,
    toggleCancelDispatchModal,
    onClickCancelDispatch,
    createDispatchToastPopup,
    submitLoading,
    setIsError,
    isError,
    onChangeTruckDriverNumber,
    truckDriverNumber,
    saudaDetailData,
    onClickMandiToolTip,
    mandiToolTipVisible,
    truckErrorMessage,
  };
}
