/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-const */
import getLang from '../../languages/addFarmer.js'; // Importing language translation function
import { useLocation, useNavigate } from 'react-router-dom';
import { appLanguages } from '../../constant/appLanguages';

import { AddFarmerProps, ContactProps } from './types.js';

import { useEffect, useState } from 'react';
import { createBridge } from '../../rpcBridge';

import useAuthenticationChecker from '../../utils/authChecker';
import { apiEndPoints } from '../../constant/apiEndPoints';
import { usePlatfromData } from '../../hooks/CrossPlatformActionHandler/platformChecker';
import PlatForms from '../../constant/platFormEnums';
import Fuse from 'fuse.js';
import { trackEvent } from '../../utils/moengage/index'; // Importing tracking functions
import Events from '../../constant/MoengageEvents'; // Importing constant events
import { getLanguageService } from './../../utils/getLanguageService';

import { languageUrls } from '../../constant/languageUrls/index';

/* The line `import bridgeFunctions from "../../utils/bridge";` is importing a module or file named
`bridgeFunctions` from the path `../../utils/bridge`. This allows the code to access functions or
utilities defined in the `bridgeFunctions` module within the current file. */
import bridgeFunctions from '../../utils/bridge';
import { PlayStoreUrl } from '../../constant/PlayStoreUrl';
import useAppLanguage from '../../utils/applanguage';

export default function AddFarmerHook(): AddFarmerProps {
  // Create a bridge for communication with native code
  const bridge = createBridge();
  const { state } = useLocation();

  // Get the authentication checker and API caller functions
  const { AuthenticationCheckerAndApiCaller } = useAuthenticationChecker();

  // Set initial state for contact loading and permission
  const [contactLoading, setContactLoading] = useState(true);
  const [contactPermission, setContactPermission] = useState(false);

  // Get platform data
  const platformData = usePlatfromData();

  // Set initial state for modal visibility and error
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [error, setError] = useState(false);

  // Set initial state for farmers list and saving loading
  const [farmersList, setFarmersList] = useState([]);

  // Set initial state for farmer details
  const [savingLoading, setSavingLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  const [farmerDetails, setFarmerDetails] = useState({
    farmerMobile: '',
    farmerName: '',
  });
  const [isErrorPopup, setIsErrorPopup] = useState(false);

  // Set initial state for search value
  const [searchValue, setSearchValue] = useState('');

  // Set initial state for contact list, current platform, contacts index, and contacts backup
  const [contactList, setContactList] = useState<ContactProps[]>([]);
  const currentPlatform = platformData?.platform;
  const [contactsIndex, setContactsIndex] = useState({});

  const [sortedContacts, setSortedContacts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;

  /**
   * The function `handleCheckboxChange` toggles the value associated with a specific mobile number in
   * the `contactsIndex` object.
   * @param {number} mobile_number - The `mobile_number` parameter in the `handleCheckboxChange` function
   * is of type `number` and represents the unique identifier of a contact in the `contactsIndex` object.
   */

  function handleCheckboxChange(mobile_number: number) {
    setContactsIndex({
      ...contactsIndex,
      [mobile_number]: !contactsIndex[mobile_number],
    });
    trackEvent(Events.onAddFarmerSelectFarmerCheckboxClick, {});
  }

  /* The line `const selectedCount = Object.values(contactsIndex).filter((item) => item ===
true).length;` is calculating the number of selected contacts in the `contactsIndex` object. */
  const selectedCount = Object.values(contactsIndex).filter(
    (item) => item === true,
  ).length;

  const { language } = useAppLanguage(
    languageUrls?.addFarmerScreenLangageUrl,
    getLang,
  );

  const navigate = useNavigate();

  useEffect(() => {
    getContactsList();
  }, []);

  // Function to handle back button press
  function onPressBack() {
    navigate(-1);
  }

  /**
   * The function `onContactUs` navigates to the "/faq/questions" page.
   */
  function onContactUs() {
    navigate('/faq/questions');
  }
  /**
   * The function `getContactsList` retrieves contacts data, filters out specific contacts, sorts them
   * alphabetically, and updates the contact list accordingly.
   */

  async function getContactsList() {
    setContactLoading(true);
    if (platformData?.platform === PlatForms?.get('application')) {
      try {
        const farmersNumberResp = await AuthenticationCheckerAndApiCaller({
          payload: {},
          uri: apiEndPoints.farmaerMobileNumber,
          requestType: 'get',
        });

        if (farmersNumberResp?.data?.status) {
          setFarmersList(farmersNumberResp?.data.data);

          bridge.sendRequestToNative(
            'GetContacts',
            null,
            (response: unknown) => {
              bridgeFunctions.PrintLog(response);
            },
          );

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (window as any).listenMessagesFromApp = function (request: any) {
            let contacts = JSON.parse(decodeURIComponent(request));

            if (contacts?.length) {
              setContactPermission(true);

              let farmersContactsObj = {};
              let contactsObj = {};
              let phoneContacts = [];
              farmersNumberResp?.data.data.forEach((value) => {
                farmersContactsObj[value] = true;
              });
              contacts.map((contactData) => {
                let name = contactData.name;

                if (
                  !farmersContactsObj[contactData.mobile_number] &&
                  contactsObj[contactData.mobile_number] === undefined
                ) {
                  contactsObj[contactData.mobile_number] = false;
                  phoneContacts.push({
                    name,
                    mobile_number: contactData.mobile_number,
                  });
                }
              });

              let sortedContacts = phoneContacts.sort((a, b) => {
                return a.name.toUpperCase() === b.name.toUpperCase()
                  ? 0
                  : a.name.toUpperCase() > b.name.toUpperCase()
                  ? 1
                  : -1;
              });
              setSortedContacts(sortedContacts);
              setContactsIndex(contactsObj);
              setContactList(sortedContacts);
              setCurrentPage(2);
              setContactLoading(false);
            } else {
              setContactPermission(false);
              setContactLoading(false);
            }
          };
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setContactLoading(false);
    }
  }

  const handleSetCurrentPage = (page: number) => {
    if (contactList.length !== sortedContacts.length) {
      setCurrentPage((lastValue) => {
        return lastValue + 1;
      });
    }
  };

  /**
   * The function `onSearch` uses Fuse.js to search for contacts based on a given text input and updates
   * the contact list accordingly.
   * @param {string} text - The `text` parameter in the `onSearch` function is a string that represents
   * the search query entered by the user. This query is used to filter the `contactsBackUp` array based
   * on the specified keys ("name" and "mobile_number") using the Fuse.js library. The filtered results
   */
  const options = { keys: ['name', 'mobile_number'] };
  const fuse = new Fuse(sortedContacts, options);

  function onSearch(text: string) {
    setSearchValue(text);

    trackEvent(Events.onAddFarmerSearchInputBoxClick, {});
    setContactList(
      text
        ? fuse.search(text).map((text) => {
            return text.item;
          })
        : sortedContacts,
    );
  }

  /**
   * The function `saveFarmerManual` saves farmer details by making an API call if the mobile number is
   * 10 digits long and the farmer name is provided.
   */
  async function saveFarmerManual() {
    let farmers = [];

    if (
      farmerDetails.farmerMobile.length === 10 &&
      farmerDetails.farmerName.length
    ) {
      setSavingLoading(true);

      farmers.push({
        mobile_number: farmerDetails.farmerMobile,
        name: farmerDetails.farmerName,
      });
    }
    if (farmers.length) {
      //setLoading(true);
      //new api demand object thats why passing first element
      try {
        let addFarmerApiPara = { farmer_to_add: farmers };

        const addFarmerApiResp = await AuthenticationCheckerAndApiCaller({
          payload: addFarmerApiPara,
          uri: apiEndPoints.addFarmers,
          requestType: 'post',
        });

        if (addFarmerApiResp.data?.status) {
          trackEvent(Events?.onAddFarmerManuallySubmitButtonClick, {});
          /* sortAndAddToStore(addFarmerApiResp.data.farmers_added); */
          setFarmerDetails({
            farmerMobile: '',
            farmerName: '',
          });
          navigate('/success', {
            state: {
              fromScreen: state?.fromScreen,
            },
          });
          setSavingLoading(false);
        }
        setSavingLoading(false);
        setIsModalVisible(false);
      } catch (error) {
        handleErrorToastPopup();
        setSavingLoading(false);
      }
    }
  }

  /**
   * The function `onToggleManualModal` toggles the visibility of a modal and resets farmer details if
   * the modal is currently visible.
   */

  function onToggleManualModal() {
    if (isModalVisible) {
      setFarmerDetails({
        farmerMobile: '',
        farmerName: '',
      });
      trackEvent(Events?.onAddFarmerManuallyCrossButtonClick, {});
    } else {
      trackEvent(Events?.onAddFarmerManuallyButtonClick, {});
    }
    setIsModalVisible(!isModalVisible);
  }

  function onChangeInput(value: string, type: string) {
    setError(false);
    if (type === 'farmerName') {
      trackEvent(Events.onAddFarmerManuallyFarmerNameInputBoxClick, {});
    } else {
      trackEvent(Events.onAddFarmerManuallyFarmerNameInputBoxClick, {});
    }
    setFarmerDetails({ ...farmerDetails, [type]: value });
  }

  /**
   * The `saveFarmer` function asynchronously saves a list of farmers by making a POST request to an
   * API endpoint and handles the response accordingly.
   */
  async function saveFarmer() {
    setSavingLoading(true);
    try {
      let farmers = [];
      sortedContacts.forEach((contact) => {
        contactsIndex[contact.mobile_number] && farmers.push(contact);
      });
      let addFarmerApiPara = { farmer_to_add: farmers };

      const addFarmerApiResp = await AuthenticationCheckerAndApiCaller({
        payload: addFarmerApiPara,
        uri: apiEndPoints.addFarmers,
        requestType: 'post',
      });
      if (addFarmerApiResp.data.status) {
        trackEvent(Events?.onSubmitFarmerListButtonClick, {});

        setSavingLoading(false);
        navigate('/success', {
          state: {
            fromScreen: state?.fromScreen,
          },
        });
      } else {
        setSavingLoading(false);
      }
    } catch (error) {
      console.log(error);
      handleErrorToastPopup();
      setSavingLoading(false);
    }
  }

  const handleErrorToastPopup = () => {
    setIsErrorPopup(true);

    setTimeout(() => {
      setIsErrorPopup(false);
    }, 5000);
  };
  /**
   * The function `onClickOpenSetting` sends a request to the native app to open the app settings and
   * prints the response in the log.
   */
  function onClickOpenSetting() {
    bridge.sendRequestToNative('OpenAppSettings', null, (response: unknown) => {
      bridgeFunctions.PrintLog(response);
    });
  }

  /**
   * The function `onClickDownloadApp` opens a specific link either in a native app or in a new browser
   * tab based on platform data.
   */
  function onClickDownloadApp() {
    const link = PlayStoreUrl;
    if (platformData?.platform === PlatForms?.get('application')) {
      bridge.sendRequestToNative(
        'OpenUrl',
        {
          link: link,
        },
        (response) => {
          bridgeFunctions.PrintLog(response);
        },
      );
    } else {
      const win = window?.open(link, '_blank');
      win?.focus();
    }
  }

  // Returning necessary functions and states
  return {
    onPressBack,
    language,
    onContactUs,
    contactList,
    contactLoading,
    contactPermission,
    currentPlatform,
    handleCheckboxChange,
    onSearch,
    onChangeInput,
    onToggleManualModal,
    isModalVisible,
    error,
    farmerDetails,
    saveFarmerManual,
    contactsIndex,
    savingLoading,
    selectedCount,
    saveFarmer,
    onClickOpenSetting,
    onClickDownloadApp,
    isErrorPopup,
    sortedContacts,
    pageLoading,
    currentPage,
    handleSetCurrentPage,
  };
}
