import { apiEndPoints } from './apiEndPoints';
export const unVerifiedRoutes = {
  guestLogin: [
    apiEndPoints?.newsFeedAction,
    apiEndPoints?.fetchAllPosts,
    apiEndPoints?.fetchBaseComments,
    apiEndPoints?.fetchCommentReply,
    apiEndPoints?.fetchPages,
    apiEndPoints?.fetchPostById,
    apiEndPoints?.fetchRevelantPosts,
    apiEndPoints?.fetchBuyerLocationList,
    apiEndPoints?.fetchCropList,
    apiEndPoints?.fetchCropVarietyList,
    apiEndPoints?.fetchTradePriceBreakup,
    apiEndPoints?.fetchTradeList,
    apiEndPoints?.fetchTradeServiciability,
    apiEndPoints?.homeScreenSectionData,
    apiEndPoints?.getRakeStatus,
    apiEndPoints?.weatherDetail,
    apiEndPoints?.getFAQQuestion,
    apiEndPoints?.updateUserPersona,
    apiEndPoints?.fetchStateDistrictsReverseGeoCode,
    apiEndPoints?.setIPLocationLogs,
    apiEndPoints?.getLanguage,
  ],
};
