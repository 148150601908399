import { useReducer, useEffect } from 'react';
import {
  MySaudaListScreen,
  FilterStatus,
  OpenCloseTab,
  SaudaOrderCard,
} from './types';
import SaudaStatusEnum from '../../constant/enums/saudaStatusEnum';
import { MySaudaCard } from '../../modal/mySauda/types';
import SaudaOpenCloseEnum from '../../constant/enums/saudaOpenCloseEnum';
import { useNavigate } from 'react-router-dom';
import { trackEvent } from '../../utils/moengage';
import Events from '../../constant/MoengageEvents';
import getLang from '../../languages/mySaudaScreen.js';
import { useAuth } from '../../context/login';
import { usePlatfromData } from '../CrossPlatformActionHandler/platformChecker';
import { unVerifiedRoutes } from '../../constant/unVerifiedRoutes';
import { loginAsGuest } from '../../modal/auth/auth.model';
import useAuthenticationCheckerHook from '../../utils/authChecker';
import { apiEndPoints } from '../../constant/apiEndPoints';
import { getMySaudaAssets } from '../../constant/imageUrls';
import { languageUrls } from '../../constant/languageUrls/index';
import useAppLanguage from '../../utils/applanguage';

const initialState = {
  saudaModalVisible: false,
  mySaudaList: [],
  mySaudaTotalRecords: 0,
  mySaudaPageNumber: 1,
  selectedFilterStatus: SaudaStatusEnum.get('status')[0],
  mySaudaLoading: false,
  selectedOpenCloseTab: SaudaOpenCloseEnum.get('openClose')[0],
  isServiceAvailable: false,
};

function saudaReducer(state, action) {
  switch (action.type) {
    case 'TOGGLE_MODAL':
      return { ...state, saudaModalVisible: !state.saudaModalVisible };
    case 'SET_FILTER_STATUS':
      return {
        ...state,
        mySaudaTotalRecords: 0,
        mySaudaList: [],
        mySaudaPageNumber: 1,
        selectedFilterStatus: action.payload,
        saudaModalVisible: false,
      };
    case 'SET_SAUDA_LIST':
      return {
        ...state,
        mySaudaList: [...state.mySaudaList, ...action.payload.list],
        mySaudaTotalRecords: action.payload.totalRecords,
        mySaudaPageNumber: state.mySaudaPageNumber + 1,
        isServiceAvailable: action.payload.isServiceAvailable,
      };
    case 'SET_LOADING':
      return { ...state, mySaudaLoading: action.payload };
    case 'SET_OPEN_CLOSE_TAB':
      return {
        ...state,
        mySaudaList: [],
        mySaudaTotalRecords: 0,
        mySaudaPageNumber: 1,
        selectedFilterStatus: SaudaStatusEnum.get('status')[0],
        selectedOpenCloseTab: action.payload,
      };
    default:
      return state;
  }
}

export default function GetMySaudaList(): MySaudaListScreen {
  const { language } = useAppLanguage(languageUrls?.mySaudaScreen, getLang);
  const userLogedIn = localStorage.getItem('loged');
  const platformData = usePlatfromData();
  const authProps = useAuth();
  const navigate = useNavigate();
  const saudaStatusEnum = SaudaStatusEnum.get('status');
  const saudaOpenCloseEnum = SaudaOpenCloseEnum.get('openClose');
  const langId = JSON.parse(localStorage.getItem('userLanguage'));
  const startLoadTime = new Date().getTime();
  const stateId = JSON.parse(localStorage.getItem('stateId')) || null;
  const districtId = JSON.parse(localStorage.getItem('districtId')) || null;
  const mySaudaAssets = getMySaudaAssets(langId?.langCode);
  const { AuthenticationCheckerAndApiCaller } = useAuthenticationCheckerHook();
  const [state, dispatch] = useReducer(saudaReducer, initialState);

  function onCloseSaudaStatusModal() {
    state?.saudaModalVisible
      ? trackEvent(Events?.onSaudaStatusCloseButtonClicked, {})
      : trackEvent(Events?.onSaudaStatusFilterButtonClick, {});
    dispatch({ type: 'TOGGLE_MODAL' });
  }

  function onSelectFilterStatus(value: FilterStatus) {
    return () => {
      trackEvent(Events?.onSaudaStatusClicked, {
        Filter_selected:
          value?.id === '1'
            ? 'Sauda in review'
            : value?.id === '2'
            ? 'PO acceptance pending'
            : value?.id === '3'
            ? 'Sauda booked'
            : 'All Saudas',
      });
      if (state.selectedFilterStatus?.id !== value?.id) {
        dispatch({ type: 'SET_FILTER_STATUS', payload: value });
      }
    };
  }

  async function fetchMySaudaList() {
    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      const response = await AuthenticationCheckerAndApiCaller({
        payload: {
          is_open: state.selectedOpenCloseTab?.id,
          limit: 10,
          page_no: state.mySaudaPageNumber,
          sauda_status_id: state.selectedFilterStatus?.id,
          supplier_state_id: stateId,
          supplier_district_id: districtId,
        },
        uri: apiEndPoints.getMySaudaList,
        requestType: 'get',
        loginMethod: 'modal',
      });
      const { sauda_list, total_records, is_trade_serviceable } =
        response?.data?.data;
      dispatch({
        type: 'SET_SAUDA_LIST',
        payload: {
          list: sauda_list,
          totalRecords: total_records,
          isServiceAvailable: is_trade_serviceable,
        },
      });
      const mySaudaEventAttribute = {
        LoadingTime: (new Date().getTime() - startLoadTime) / 1000,
        Tab: state.selectedOpenCloseTab.id === 1 ? 'Open' : 'Close',
      };
      trackEvent(Events.onStartMySaudasScreen, mySaudaEventAttribute);
    } catch (error) {
      if (error?.code === 401 || error?.code === 426) {
        if (platformData?.platform !== 'APP') {
          if (unVerifiedRoutes?.guestLogin?.includes(error?.path)) {
            dispatch({ type: 'SET_LOADING', payload: true }); // Ensure loader shows up during guest login
            await loginAsGuest();
          } else {
            authProps.setMobileNumberModalVisible(true);
          }
        }
      }
      console.error(error);
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  }

  const fetchMoreSaudaList = () => {
    if (state?.mySaudaTotalRecords > state.mySaudaList?.length) {
      fetchMySaudaList();
    }
  };

  function onClickOpenCloseTab(value: OpenCloseTab) {
    return () => {
      trackEvent(
        value?.id === saudaOpenCloseEnum[0]?.id
          ? Events?.onOpenSaudasButtonClick
          : Events?.onClosedSaudasButtonClick,
        {},
      );
      if (state.selectedOpenCloseTab?.id !== value?.id) {
        dispatch({ type: 'SET_OPEN_CLOSE_TAB', payload: value });
      }
    };
  }

  function onClickAcceptPo(id: number) {
    return () => navigate(`/trades/mySauda/${id}`);
  }

  function onClickSaudaOrderCard(value: SaudaOrderCard) {
    return () => {
      trackEvent(Events?.onMySaudasCardButtonClick, {
        Buyer_name: value?.buyerName,
        Crop_name: value?.cropName,
        Quantity: value?.quantity,
        Payment_Term: value?.paymentTerm,
        'PO Status': value?.poStatus,
        'Number of Trucks': value?.numberOfTrucks,
      });
      navigate(`/trades/mySauda/${value?.id}`, { state: 'my_sauda_list' });
    };
  }

  function onClickBack() {
    trackEvent(Events?.onMySaudasBackButtonClick, {});
    if (platformData.platform === 'WEB') {
      authProps.setMobileNumberModalVisible(false);
    }
    navigate('/trades');
  }

  useEffect(() => {
    window.addEventListener('popstate', onClickBack);
    return () => {
      window.removeEventListener('popstate', onClickBack);
    };
  }, []);

  useEffect(() => {
    fetchMySaudaList();
  }, [
    state.selectedFilterStatus?.id,
    state.selectedOpenCloseTab?.id,
    userLogedIn,
  ]);

  const trackMySaudaScreenEnd = () => {
    const closedMySaudaScreenAttributes = {
      TimeSpent: (new Date().getTime() - startLoadTime) / 1000,
    };
    trackEvent(Events.onEndMySaudasScreen, closedMySaudaScreenAttributes);
  };

  useEffect(() => {
    return trackMySaudaScreenEnd;
  }, []);

  return {
    onCloseSaudaStatusModal,
    saudaModalVisible: state?.saudaModalVisible,
    onSelectFilterStatus,
    selectedFilterStatus: state?.selectedFilterStatus,
    mySaudaList: state?.mySaudaList,
    fetchMoreSaudaList,
    mySaudaLoading: state?.mySaudaLoading,
    onClickOpenCloseTab,
    selectedOpenCloseTab: state?.selectedOpenCloseTab,
    onClickAcceptPo,
    onClickBack,
    onClickSaudaOrderCard,
    language,
    isServiceAvailable: state?.isServiceAvailable,
    mySaudaAssets,
  };
}
