import getLang from '../../languages/mySaudaScreen.js';
import getLanguage from '../../utils/getLanguage/index';

const SaudaStatusEnum = new Map();
const language = getLanguage(getLang);

SaudaStatusEnum.set('status', [
  { label: language?.allSaudas, id: '', labelKey: 'allSaudas' },
  { label: language?.saudaInReview, id: '1', labelKey: 'saudaInReview' },
  {
    label: language?.poAccpetancePending,
    id: '2',
    labelKey: 'poAccpetancePending',
  },
  { label: language?.saudaBooked, id: '3', labelKey: 'saudaBooked' },
  /* { label: "Sauda cancelled", id: 4 },
  { label: "Sauda Rejected", id: 5 },
  { label: "Sauda completed", id: 6 }, 
  { label: "Po Rejected", id: 7 }, 
  */
]);

export default SaudaStatusEnum;
