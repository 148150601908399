/**
 * Custom hook for managing the user's profile.
 * @returns An object containing various functions and state variables related to the user's profile.
 */
import { useEffect, useState } from 'react';
import { LanguageParams, myProfileHook } from './types';
import useAuthenticationChecker from '../../utils/authChecker';
import { apiEndPoints } from '../../constant/apiEndPoints';
import {
  getUserProfileFromStorage,
  setProfileToStorage,
} from '../../modal/auth/auth.model';
import PlatForms from '../../constant/platFormEnums';
import { usePlatfromData } from '../CrossPlatformActionHandler/platformChecker';
import { createBridge } from '../../rpcBridge';
import bridgeFunctions from '../../utils/bridge';
import { Profile } from '../auth/types';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_LANGUAGE } from '../../constant/language';
import { trackEvent } from '../../utils/moengage/index';
import MoengageEvents from '../../constant/MoengageEvents';
import { PrivacyPolicy } from '../../constant/PrivacyPolicyUrl';
import { PlayStoreUrl } from '../../constant/PlayStoreUrl';
import compareObjects from '../../utils/compareObjects/index'; //compare objects
import { getMyProfileAssets } from '../../constant/imageUrls/index';
import { WEB_APP_VERSION } from '../../constant/versions';
import { fetchLanguage } from '../../utils/languageApi/fetchLanguage';
import { languageUrls } from '../../constant/languageUrls';
import { useLanguage } from '../../context/language';
/**
 * Custom hook for managing the user's profile.
 * @returns An object containing various functions and state variables related to the user's profile.
 */
export default function useMyProfilehook(): myProfileHook {
  const startLoadTime = new Date().getTime(); // Start time for the component
  const { languageData } = useLanguage();
  /**
   * Custom hook for handling authentication and API calls.
   */
  const { AuthenticationCheckerAndApiCaller } = useAuthenticationChecker();

  /**
   * React hook for navigation.
   */
  const navigate = useNavigate();

  /**
   * Custom hook for retrieving platform data.
   */
  const platform = usePlatfromData();

  /**
   * Create a bridge for communication with native code.
   */
  const bridge = createBridge();

  /**
   * Get the active system language.
   */
  const activeSystemLang = Intl.DateTimeFormat().resolvedOptions().locale;

  /**
   * Determine the system language based on the active system language.
   */
  const systemLanguage = activeSystemLang?.includes('en')
    ? languageData && languageData[1].langCode
    : languageData && languageData[0].langCode;

  /**
   * Determine the system language ID based on the active system language.
   */
  const systemLanguageId = activeSystemLang?.includes('en')
    ? languageData && languageData[1].backendLanguageCode
    : languageData && languageData[0].backendLanguageCode;

  /**
   * Retrieve the language code from localStorage.
   */
  const langId = JSON.parse(localStorage.getItem('userLanguage'));

  /**
   * Initialize the current language.
   */
  const currentLanguage = langId || systemLanguage || DEFAULT_LANGUAGE;

  const myProfileAssets = getMyProfileAssets(langId?.langCode);

  /**
   * Get the merchant profile from storage.
   */
  const merchnatProfile = getUserProfileFromStorage() || null;

  const [languageFile, setLanguageFile] = useState({});

  /**
   * Get the language based on the language code.
   */
  const language =
    languageFile[langId?.langCode ? langId?.langCode : systemLanguage];

  /**
   * State variable for storing the user's profile.
   */
  const [profile, setProfile] = useState<Profile>(merchnatProfile || null);

  /**
   * State variable for storing the selected language.
   */
  const [selectedLanguage, setSelectedLanguage] = useState(currentLanguage);

  /**
   * State variable for toggling the language modal.
   */
  const [isLanguageModalOpen, toggleLanguageModal] = useState<boolean>(false);

  /**
   * State variable for indicating if the language selection is in progress.
   */
  const [isLanguageSelectedInProgress, setLanguageSelectedInProgeress] =
    useState<boolean>(false);

  /**
   * State variable for storing the app version.
   */
  const [appVersion, setAppVersion] = useState<string>('');

  /**
   * State variable for storing the logout modal visible.
   */
  const [logOutModalVisible, setLogOutModalVisible] = useState<boolean>(false);

  /**
   * State variable for storing the loading of page.
   */
  const [loading, setLoading] = useState<boolean>(false);

  // Get the merchant occupation ID
  const occupation = merchnatProfile?.merchant_occupation_id || null;

  const [error, setError] = useState<boolean>(false); // State variable for storing the error status

  async function getLanguageJson() {
    try {
      const url = languageUrls?.myProfileScreenLangageUrl;
      const result = await fetchLanguage(url);
      setLanguageFile(result);
    } catch (error) {
      console.log('object');
    } finally {
      console.log('object');
    }
  }

  /**
   * Fetch the user's profile from the API.
   */
  async function fetchMyProfile() {
    setLoading(true);
    try {
      const queryParameter = {};
      const response = await AuthenticationCheckerAndApiCaller({
        uri: apiEndPoints?.getMerchantProfile,
        requestType: 'post',
        payload: queryParameter,
      });
      if (response?.data?.status) {
        setProfile(response?.data?.data);
        if (PlatForms.get('application') === platform?.platform) {
          if (
            !compareObjects(merchnatProfile, response?.data?.data, 'webViewRef')
          ) {
            //need better appraoch to compare objects
            bridge.sendRequestToNative(
              // Sending request to native using bridge
              'updateUserProfile',
              response?.data?.data,
              (response) => {
                bridgeFunctions.PrintLog(response);
              },
            );
          }
        }
        setProfileToStorage(response?.data?.data);
        trackEvent(MoengageEvents?.onStartMyProfileScreen, {
          'Loading Time': new Date().getTime() - startLoadTime,
          FROM: 'HOME SCREEN',
        });
      }
      setLoading(false);
    } catch (error) {
      trackEvent(MoengageEvents?.onStartMyProfileScreen, {
        'Loading Time': new Date().getTime() - startLoadTime,
        FROM: 'HOME SCREEN',
      });
      setError(true);
      setLoading(false);
    }
  }

  /**
   * Handle the click event for the "My Profile" button.
   */
  function onClickMyProfile(type: 'string') {
    trackEvent(MoengageEvents?.onMyProfileEditProfileButtonClick, {
      from: type,
    });
    navigate('/editProfile');
  }

  /**
   * Handle the language selection.
   * @param languageItem The selected language item.
   */
  function handleLanguageSelect(languageItem: LanguageParams) {
    setSelectedLanguage(languageItem);
  }

  /**
   * Toggle the language modal.
   */
  function onLanguageModalToggle() {
    if (isLanguageModalOpen) {
      trackEvent(MoengageEvents?.onChooseLanguageButtonClick, {});
    }
    setSelectedLanguage(
      langId || {
        langCode: systemLanguage,
        langId: systemLanguageId,
      },
    );
    toggleLanguageModal(!isLanguageModalOpen);
  }

  /**
   * Handle the language change.
   */
  function handleLanguageChange() {
    // Handling language change
    if (localStorage.getItem('userLanguage') !== selectedLanguage) {
      setLanguageSelectedInProgeress(!isLanguageSelectedInProgress);
      localStorage.setItem('userLanguage', JSON.stringify(selectedLanguage));
      if (platform?.platform === PlatForms.get('application')) {
        bridge.sendRequestToNative(
          'setAppLanguage',
          {
            langCode: selectedLanguage?.langCode,
          },
          (response) => {
            bridgeFunctions.PrintLog(response);
          },
        );
      }
      toggleLanguageModal(!isLanguageModalOpen);
      setLanguageSelectedInProgeress(false);
    } else {
      toggleLanguageModal(!isLanguageModalOpen);
    }
  }

  /**
   * Redirect to the Play Store.
   */
  function redirectToPlayStore() {
    trackEvent(MoengageEvents?.onRateAppButtonClick, {});
    window.open(PlayStoreUrl, '_blank');
  }

  /**
   * Navigate to the help page.
   */
  function help() {
    trackEvent(MoengageEvents?.onMyProfileHelpButtonClick, {});
    navigate('/faq/questions');
  }

  /**
   * Open the privacy policy page in a new tab.
   */
  function onClickPrivacyPolicy() {
    trackEvent(MoengageEvents?.onPrivacyPolicyButtonClick, {});
    window.open(PrivacyPolicy, '_blank');
  }

  /**
   * Handle the click event for the logout button.
   */
  function onClickLogout() {
    trackEvent(MoengageEvents?.onLogoutButtonClick, {});
    setLogOutModalVisible(true);
  }

  /**
   * Get the app version.
   */
  async function getAppVersion() {
    if (PlatForms.get('application') === platform?.platform) {
      bridge.sendRequestToNative('getCodePushVersion', null, (response) => {
        bridgeFunctions.PrintLog(response);
      });
      (window as any).listenMessagesFromApp = function (request: any) {
        const data = JSON.parse(request);
        if (data?.appVersion) {
          setAppVersion(data?.appVersion);
        }
      };
    } else {
      setAppVersion(WEB_APP_VERSION);
    }
  }

  /**
   * Handle the click event for the back button.
   */
  function onClickBack() {
    navigate('/');
  }

  /**
   * Handle the click event for the close logout modal button.
   */
  function onClickCloseLogOutModal() {
    setLogOutModalVisible(false);
  }

  /**
   * Handle the click event for the confirm logout modal button.
   */
  function confirmLogout() {
    trackEvent(MoengageEvents?.onEndLogoutConfirmationScreen, {
      from: 'Yes Button Clicked',
    });
    if (platform?.platform === PlatForms.get('application')) {
      localStorage.clear();
      sessionStorage.clear();
      bridge.sendRequestToNative('logOutFromApp', null, (response) => {
        bridgeFunctions.PrintLog(response);
      });
    } else {
      localStorage.clear();
      sessionStorage.clear();
      navigate('/login');
    }
  }

  function onClickMyFarmer() {
    navigate('/myFarmers');
  }

  /**
   * Fetch the user's profile when the component mounts.
   */
  useEffect(function () {
    fetchMyProfile();
  }, []);

  /**
   * Get the app version when the component mounts.
   */
  useEffect(function () {
    getAppVersion();
    return () => {
      trackEvent(MoengageEvents?.onEndMyProfileScreen, {
        'Time Spent': new Date().getTime() - startLoadTime,
        'App Version': appVersion,
      });
    };
  }, []);

  useEffect(() => {
    getLanguageJson();
  }, []);

  useEffect(() => {
    setSelectedLanguage(
      langId || {
        langCode: systemLanguage,
        langId: systemLanguageId,
      },
    );
  }, [localStorage.getItem('userLanguage')]);

  return {
    language,
    profile,
    onClickMyProfile,
    handleLanguageSelect,
    onLanguageModalToggle,
    selectedLanguage,
    isLanguageModalOpen,
    handleLanguageChange,
    isLanguageSelectedInProgress,
    redirectToPlayStore,
    help,
    onClickPrivacyPolicy,
    onClickLogout,
    appVersion,
    onClickBack,
    onClickCloseLogOutModal,
    logOutModalVisible,
    confirmLogout,
    loading,
    onClickMyFarmer,
    occupation,
    error,
    myProfileAssets,
    languageData,
  };
}
