// Import Box component from @mui/joy for layout and styling
import { Box } from '@mui/joy';
// Import React for component creation
import React from 'react';
// Import Header component for displaying the page header
import Header from './Header';
// Import Styles from the module CSS for custom styling
import Styles from './style.module.css';
// Import UserProfile component for displaying user profile information
import UserProfile from './UserProfile';
// Import FeatureList component for displaying a list of features/options
import FetureList from './FeatureList';
// Import myProfileHook type from the hooks for type checking of props
import { myProfileHook } from '../../hooks/MyProfile/types';
// Import LanguageSelectionModal for language selection functionality
import LanguageSelectionModal from './LanguageSelection/LanguageSelection'; // Import the LanguageSelectionModal component
import LogOutModal from './LogOutModal'; // Import the LogOutModal component
import Toast from '../../ComponentV2/Toast'; // Import the Toast component

// Define and export the MyProfile functional component with props typed as myProfileHook
export default function MyProfile(props: myProfileHook) {
  // Destructure props to extract individual properties for easier access
  const {
    language, // Current language setting
    profile, // User profile information
    onClickMyProfile, // Function to handle profile click actions
    handleLanguageChange, // Function to handle language changes
    selectedLanguage, // Currently selected language
    isLanguageModalOpen, // Boolean indicating if the language modal is open
    isLanguageSelectedInProgress, // Boolean indicating if language selection is in progress
    handleLanguageSelect, // Function to handle the final selection of a language
    onLanguageModalToggle, // Function to toggle the language modal visibility
    redirectToPlayStore, // Function to redirect to the Play Store
    help, // Help information or function
    onClickPrivacyPolicy, // Function to handle privacy policy click actions
    onClickLogout, // Function to handle logout actions
    appVersion, // Current application version
    onClickBack, // Function to handle back navigation
    onClickCloseLogOutModal, // Function to close the logout modal
    logOutModalVisible, // Boolean indicating if the logout modal is visible
    confirmLogout, // Function to confirm the logout action
    loading, // Boolean indicating if the component is in a loading state
    onClickMyFarmer, // Function to handle the click event on the My Farmer button
    occupation, // The occupation of the user
    error, // Boolean indicating if there is an error
    myProfileAssets,
    languageData,
  } = props;
  // Render the MyProfile component structure
  return (
    // Box component for layout, using bgcolor and className for styling
    <Box bgcolor={'background.body'} className={Styles?.container}>
      {/* Header component with props for language, profile click, and back navigation */}
      <Header
        language={language}
        onClickMyProfile={onClickMyProfile}
        onClickBack={onClickBack}
        loading={loading}
      />
      {/* UserProfile component with profile information and profile click handler */}
      <UserProfile
        profile={profile}
        onClickMyProfile={onClickMyProfile}
        loading={loading}
      />
      {/* FeatureList component with various props for features and actions */}
      <FetureList
        language={language}
        onLanguageModalToggle={onLanguageModalToggle}
        redirectToPlayStore={redirectToPlayStore}
        help={help}
        onClickPrivacyPolicy={onClickPrivacyPolicy}
        onClickLogout={onClickLogout}
        appVersion={appVersion}
        loading={loading}
        onClickMyFarmer={onClickMyFarmer}
        occupation={occupation}
        languageToggleIcon={myProfileAssets.languageToggleIcon}
      />
      {/* LanguageSelectionModal component for handling language selection */}
      <LanguageSelectionModal
        handleLanguageChange={handleLanguageChange}
        selectedLanguage={selectedLanguage}
        isLanguageModalOpen={isLanguageModalOpen}
        isLanguageSelectedInProgress={isLanguageSelectedInProgress}
        handleLanguageSelect={handleLanguageSelect}
        onLanguageModalToggle={onLanguageModalToggle}
        language={language}
        languageData={languageData}
      />
      {/* LogOutModal component for handling logout confirmation */}
      <LogOutModal
        language={language}
        onClickCloseLogOutModal={onClickCloseLogOutModal}
        logOutModalVisible={logOutModalVisible}
        confirmLogout={confirmLogout}
      />
      <Toast
        isError={true}
        message={language?.requestFailErrorMessage}
        isOpen={error}
      />{' '}
      {/* Toast component for displaying error messages */}
    </Box>
  );
}
