import React, { useCallback } from 'react';
import Styles from './style.module.css';
import Modal from '../../../ComponentV2/Modal';
import { Button } from '@mui/joy';
import RadioButtonCheckedRoundedIcon from '@mui/icons-material/RadioButtonCheckedRounded';
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';

interface LanguageParams {
  langCode: string;
  langId: number;
}
interface PayloadType {
  handleLanguageSelect: (props: LanguageParams) => void;
  onLanguageModalToggle: () => void;
  handleLanguageChange: () => void;
  selectedLanguage: string;
  isLanguageModalOpen: boolean;
  isLanguageSelectedInProgress: boolean;
  language: { [key: string]: any };
  languageData: any;
}

function LanguageCard({ item, selectedLanguage, handleLanguageSelect, index }) {
  const handleSelect = useCallback(() => {
    handleLanguageSelect({
      langCode: item?.langCode,
      langId: item?.backendLanguageCode,
    });
  }, [item.langCode]);
  return (
    <div
      id={'loginScreenSelectLangugeCardTestId' + index}
      onClick={handleSelect}
      className={
        item?.langCode === selectedLanguage?.langCode
          ? Styles.activeCardMainContainer
          : Styles.inActiveCardMainContainer
      }
    >
      <div className={Styles.cardDetailContainer}>
        <div className={Styles.cardTextContainer}>
          <div className={Styles.langaugeTextLg}>
            {item?.languageNameInHindi}
          </div>
          <div className={Styles.langaugeTextSm}>
            {item.languageNameInEnglish}
          </div>
        </div>
        <div>
          {item?.langCode === selectedLanguage?.langCode ? (
            <RadioButtonCheckedRoundedIcon
              htmlColor="#008B74"
              sx={{ height: 28, width: 28 }}
            />
          ) : (
            <RadioButtonUncheckedRoundedIcon
              htmlColor="#C2C7CF"
              sx={{ height: 28, width: 28 }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

const SelectCropModal = ({
  handleLanguageSelect,
  onLanguageModalToggle,
  handleLanguageChange,
  selectedLanguage,
  isLanguageModalOpen,
  isLanguageSelectedInProgress,
  language,
  languageData,
}: PayloadType) => {
  const appLanguages = languageData && languageData;
  const UiModal = (
    <div className={Styles.wrapper}>
      <div className={Styles.gridStyle}>
        {appLanguages?.map(function (item, index) {
          return (
            <LanguageCard
              item={item}
              selectedLanguage={selectedLanguage}
              handleLanguageSelect={handleLanguageSelect}
              index={index}
            />
          );
        })}
      </div>
      <div className={Styles.proceedButtonContainer}>
        <Button
          size="lg"
          style={{ borderRadius: 12 }}
          fullWidth={true}
          onClick={handleLanguageChange}
          loading={isLanguageSelectedInProgress}
          id="loginScreenChangeLanguageProceedTestId"
        >
          {language?.proceed}
        </Button>
      </div>
    </div>
  );

  return (
    <Modal
      id="loginScreenCloseModalTestId"
      onClose={onLanguageModalToggle}
      isCloseButtonOption={true}
      title={language?.selectLanguage}
      isOpen={isLanguageModalOpen}
      children={UiModal}
    />
  );
};

export default SelectCropModal;
