// AuthContext.tsx
import React, { createContext, useContext, ReactNode } from 'react';
import useGetOtp from './useGetOtp'; // Assuming your custom hook file is named useGetOtp
import { GetOtp } from './types';
import LoginView from './TradeLoginPage/TraderLogin';

// Define the context type
type AuthContextType = GetOtp;

// Create the context
const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Create the provider component
export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const authContextValue = useGetOtp(); // Use your custom hook here

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to consume the context
export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export { LoginView };
